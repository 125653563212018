import React from "react";
import {
  Button,
  Table,
  TableContentSelector,
  TablePageSizeSelector,
  TablePagination,
  TablePreferences,
  TableSorting,
  TableWrapLines
} from "@amzn/awsui-components-react";
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  SORTABLE_COLUMNS
} from "./MiniCreditCodeListConfig";


const Header = ({counter, itemsLoading, onRefresh, onShowCodesClick, codesDecrypting}) => {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title">
        <h2>
          Assigned Credit Codes
          {counter ? <span className="awsui-util-header-counter"> {counter}</span> : ''}
        </h2>
      </div>
      <div className="awsui-util-action-stripe-group">
        <Button icon="refresh" loading={itemsLoading} onClick={onRefresh}/>
        <Button variant="primary" loading={codesDecrypting} onClick={onShowCodesClick}>Reveal Codes</Button>
      </div>
    </div>
  );
};

export default class MiniCreditCodeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pagesCount: 0
    };
  }

// Keeps track of how many funding requests are selected
  headerCounter(items, hasMore) {
    return hasMore
      ? `(${items.length}+)`
      : `(${items.length})`;
  }

// Updates the page size in preferences
  onPaginationChange({detail: {pageSize, pagesCount}}) {
    this.setState({
      pageSize,
      pagesCount
    });
  }

  render() {
    const {showCreditCodes, decryptingCodes} = this.props

    return (
      <Table
        columnDefinitions={COLUMN_DEFINITIONS(showCreditCodes, decryptingCodes)}
        items={this.props.creditCodes}
        loading={this.props.itemsLoading}
        loadingText={"Loading credit codes..."}
        header={
          <Header
            counter={this.headerCounter(this.props.creditCodes, this.props.hasMore)}
            itemsLoading={this.props.itemsLoading}
            codesDecrypting={this.props.codesDecrypting}
            onRefresh={() => this.props.onRefresh()}
            onShowCodesClick={this.props.onShowCodesClick}
          />
        }
        features={['pagination', 'sorting']}
        empty={<div className="awsui-util-p-m">
          <div className="awsui-util-pb-s">No credit codes</div>
        </div>}
      >
        <TablePagination
          openEnd={this.props.hasMore}
          onPaginationChange={this.onPaginationChange.bind(this)}
          onNextPageClick={(event => {
              if (!event.detail.requestedPageAvailable || event.detail.requestedPageIndex >= this.state.pagesCount - 1) {
                this.props.onRefresh(true)
              }
            }
          )}
          pageSize={this.state.pageSize}
        />
        <TableSorting sortableColumns={SORTABLE_COLUMNS}/>
        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
          <TablePageSizeSelector title="Page size" options={PAGE_SELECTOR_OPTIONS}/>
          <TableWrapLines label="Wrap lines" description="Check to see all the text and wrap the lines" value={false}/>
          <TableContentSelector title="Select visible columns" options={CONTENT_SELECTOR_OPTIONS}/>
        </TablePreferences>
      </Table>
    );
  }
}
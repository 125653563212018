import {AppLayout, BreadcrumbGroup} from '@amzn/awsui-components-react';
import React from "react";
import Navigation from "../Navigation/Navigation";
import {ErrorAlert, RequestLoading} from "../FormComponents/RequestHelpers";
import FundingRequestForm from "./FundingRequestForm";
import {API, graphqlOperation} from "aws-amplify";
import {getFundingRequest} from "../../graphql/standardAccess";

const Breadcrumbs = ({requestName, requestId}) => (
  <BreadcrumbGroup
    items={[
      {
        text: 'BEE Tool',
        href: '#/Dashboard'
      },
      {
        text: 'Funding Requests',
        href: '#/FundingRequest/list'
      },
      {
        text: requestName,
        href: requestId ? '#/FundingRequest/view/' + requestId : '#/FundingRequest/list'
      },
      {
        text: 'Edit'
      }
    ]}
  />
);

export default class EditFundingRequestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toolsOpen: false,
      initialFormState: null,
      fundingRequestErrorText: ''
    }

    this.fetchFundingRequest = this.fetchFundingRequest.bind(this)
  }

  componentDidMount() {
    this.fetchFundingRequest()
  }

  async fetchFundingRequest() {
    this.setState({fundingRequestErrorText: ''})
    try {
      const itemsRes = await API.graphql(graphqlOperation(getFundingRequest, {'id': this.props.match.params.id}))
      this.setState({initialFormState: itemsRes.data.getFundingRequest})
    } catch (e) {
      console.log("Error fetching funding request: ", e)
      this.setState({fundingRequestErrorText: e.errors ? e.errors[0].message : e.toString()})
    }
  }

  render() {
    const {initialFormState} = this.state
    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState} />}
        notifications={<ErrorAlert
                header={"Error Loading Funding Request"}
                errorText={this.state.fundingRequestErrorText}
                onRetry={this.fetchFundingRequest}/>}
        breadcrumbs={<Breadcrumbs requestId={initialFormState ? initialFormState.id : null}
                                  requestName={initialFormState ? initialFormState.projectName : "Loading..."}/>}
        content={initialFormState ? <FundingRequestForm initialState={initialFormState}/> : <RequestLoading/>}
        contentType="form"
        toolsHide={true}
      />
    )
  }
}
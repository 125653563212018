import React, {useState} from "react";

import {Button, ButtonDropdown, ColumnLayout, Icon, Spinner} from "@amzn/awsui-components-react";
import moment from "moment";
import {ParagraphText} from "../FormComponents/TextUtils";
import InfiniteScroll from "react-infinite-scroll-component";
import {API, graphqlOperation} from "aws-amplify";
import {listThreadItemsByFundingRequest} from "../../graphql/standardAccess";
import {RequiredTextArea} from "../FormComponents/RequiredInputs";

const CreatedAtHeader = ({fundingRequest}) => {
  return (
    <>
      <div className="chat-container awsui-util-container no-marg above no-pad">
        <div className="chat-head small-pad">
          <svg height="50" width="50">
            <circle cx="25" cy="25" r="22" stroke="black" strokeWidth="1" fill="lightgrey"/>
          </svg>
        </div>
        <div className="chat-text no-pad">
          <div className="awsui-util-container-header above">
            <h4 className="no-pad awsui-util-d-i">{fundingRequest.requestor.fullName}
              <p className="awsui-util-d-i"> submitted this request</p></h4>
            <div className="awsui-util-container-header-description">
              {moment(fundingRequest.submittedAt).fromNow()}
            </div>
          </div>
        </div>
      </div>
      <div className="no-pad box-joiner-container behind no-circle-joiner">
        <div className="joiner-line-wrapper">
          <svg width="40" className="chat-container test">
            <line x1="20" y1="-1000" x2="20" y2="1000" strokeWidth="2" stroke="#687078"/>
          </svg>
        </div>
      </div>
    </>
  )
}

const Comment = ({threadItem, nextIsBubble}) => {
  return (
    <>
      <div className="chat-container awsui-util-container no-pad no-marg above">
        <div className="chat-head small-pad">
          <svg height="50" width="50">
            <circle cx="25" cy="25" r="22" stroke="black" strokeWidth="1" fill="lightgrey"/>
          </svg>
        </div>
        <div className="chat-text no-pad">
          <div className="awsui-util-container-header small-pad">
            <h4 className="no-pad awsui-util-d-i">{threadItem.from.fullName} </h4> <p
            className="awsui-util-d-i">({threadItem.from.alias}@)</p>
            <div className="awsui-util-container-header-description no-pad">
              {moment(threadItem.createdAt).fromNow()}
            </div>
          </div>
          <div className="small-pad">
            <ParagraphText text={threadItem.content}/>
          </div>
        </div>
      </div>
      {!nextIsBubble ?
        <div className="no-pad box-joiner-container behind no-circle-joiner">
          <div className="joiner-line-wrapper">
            <svg width="40" className="chat-container test">
              <line x1="20" y1="-1000" x2="20" y2="1000" strokeWidth="2" stroke="#687078"/>
            </svg>
          </div>
        </div>
        : null}
    </>
  )
}

const EventBubble = ({threadItem}) => {
  let colorClass = ""
  let iconType = ""
  let actionText = ""
  if (threadItem.type === "Action") {
    if (threadItem.actionType === "Withdraw") {
      colorClass = "awsui-util-status-inactive"
      iconType = "status-stopped"
      actionText = "Withdrew this request"
    } else if (threadItem.actionType === "Approve") {
      colorClass = "awsui-util-status-positive"
      iconType = "status-positive"
      actionText = "Approved this request"
    } else if (threadItem.actionType === "Reject") {
      colorClass = "awsui-util-status-negative"
      iconType = "status-negative"
      actionText = "Denied this request"
    }
  } else if (threadItem.type === "PropertyModification") {
    iconType = "edit"
    if (threadItem.modifiedProp === 'Request Description') {
      actionText = `Modified request description`
    } else if (threadItem.modifiedProp === 'Project Outcomes'){
      actionText = `Updated project outcomes`
    } else {
      const truncate = threadItem.newVal.length > 40
      const new_val = threadItem.newVal.slice(0, 40) + (truncate ? '...' : '')
      actionText = `Changed ${threadItem.modifiedProp} to '${new_val}'`
    }
  }

  return (
    <div className="no-pad box-joiner-container behind">
      <div className="joiner-line-wrapper">
        <svg width="40" height="100%" className="chat-container test">
          <line x1="20" y1="-1000" x2="20" y2="1000" strokeWidth="2" stroke="#687078"/>
          <circle cx="20" cy="30" r="10" stroke="#687078" strokeWidth="2" fill="#FFFFFF"/>
        </svg>
      </div>
      <div className="awsui-util-pt-l awsui-util-pb-l awsui-util-ml-xxl awsui-util-d-b">
        <div className={`awsui-util-ml-m awsui-util-status-i ${colorClass}`}>
          <Icon name={iconType} className="awsui-util-mr-xs"/>
          <b className="awsui-util-mr-xs">{threadItem.from.fullName}</b>
          {actionText}
          <i> - {moment(threadItem.createdAt).fromNow()}</i>
        </div>
      </div>
    </div>
  )
}

const AddCommentForm = ({commentFieldText,
                          isApprover,
                          inApprovableState,
                          onCommentTextChanged,
                          onCommentWithAction,
                          onPlainCommentSubmit}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  return (
    <div>
      <div className="awsui-util-container
                      awsui-util-no-gutters
                      awsui-util-p-m
                      awsui-util-d-ib
                      full-width
                      above">
        <RequiredTextArea placeholder="Leave a comment..."
                          hasSubmitted={hasSubmitted}
                          value={commentFieldText}
                          onChange={(e) => {
                            onCommentTextChanged(e.detail.value)
                          }}
        />
        { isApprover && inApprovableState ?
            <ButtonDropdown className="awsui-util-f-r awsui-util-mt-s"
                        variant="primary"
                        items={[
                          {
                            "text": "Comment",
                            "id": "default"
                          },
                          {
                            "text": "Comment and Approve",
                            "id": "Approve"
                          },
                          {
                            "text": "Comment and Deny",
                            "id": "Reject"
                          }
                        ]}
                        onItemClick={(e) => {
                          if (e.detail.id !== "default") {
                            onCommentWithAction(commentFieldText, e.detail.id)
                          } else {
                            setHasSubmitted(commentFieldText === "")
                            onPlainCommentSubmit()
                          }
                        }}>
              Comment
            </ButtonDropdown>
            :
            <Button className="awsui-util-f-r awsui-util-mt-s"
                    variant="primary"
                    onClick={() => {
                      setHasSubmitted(commentFieldText === "")
                      onPlainCommentSubmit()
                    }}>
              Comment
            </Button>
          }
      </div>
    </div>
  )
}

export default class FundingRequestDiscussionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      threadItems: this.props.fundingRequest.discussionThread.items,
      nextToken: this.props.fundingRequest.discussionThread.nextToken
    }

    this.fetchNextPage = this.fetchNextPage.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.fundingRequest.discussionThread !== this.props.fundingRequest.discussionThread) {
      this.setState({
        threadItems: this.props.fundingRequest.discussionThread.items,
        nextToken: this.props.fundingRequest.discussionThread.nextToken
      })
    }
  }

  async fetchNextPage() {
    if (this.state.nextToken === null) {
      const e = "Next token not found. Needed to fetch next page"
      console.log("Error fetching comments: ", e)
      this.props.onUpdateErrorText(e.errors ? e.errors[0].message : e.toString())
      return
    }

    try {
      this.props.onUpdateErrorText('')
      const itemsRes = await API.graphql(graphqlOperation(
        listThreadItemsByFundingRequest,
        {'fundingRequestId': this.props.fundingRequest.id, 'nextToken': this.state.nextToken}
      ))
      this.setState((prevState) => {
        return {
          threadItems: [...prevState.threadItems, ...itemsRes.data.listThreadItemsByFundingRequest.items],
          nextToken: itemsRes.data.listThreadItemsByFundingRequest.nextToken
        }
      })
    } catch (e) {
      console.log("Error fetching comments: ", e)
      this.props.onUpdateErrorText(e.errors ? e.errors[0].message : e.toString())
    }
  }

  render() {
    return (
      <>
        <h1 className="awsui-util-mb-s awsui-util-mt-m">
          Discussion
        </h1>
        <div className="awsui-util-no-gutters awsui-form-content max-width-800">
          <ColumnLayout>
            <div data-awsui-column-layout-root="true" className="no-marg">
              <CreatedAtHeader fundingRequest={this.props.fundingRequest}/>
              <InfiniteScroll
                style={{"overflow": "visible"}}
                className="no-pad big-space-below"
                next={this.fetchNextPage}
                dataLength={this.state.threadItems.length}
                endMessage={<AddCommentForm
                  isApprover={this.props.isApprover}
                  inApprovableState={this.props.fundingRequest.status === "PendingApproval"}
                  commentFieldText={this.props.commentFieldText}
                  onCommentTextChanged={this.props.onCommentTextChanged}
                  onCommentWithAction={this.props.onCommentWithAction}
                  onPlainCommentSubmit={this.props.onPlainCommentSubmit}
                />}
                loader={<h4 className="awsui-util-pt-s awsui-util-pb-l"><Spinner/> Loading more comments...</h4>}
                hasMore={this.state.nextToken !== null}>
                {this.state.threadItems.map((threadItem, i) => {
                  if (threadItem.type === "Comment") {
                    return <Comment key={i}
                                    threadItem={threadItem}
                                    nextIsBubble={
                                      this.state.threadItems[i + 1] && this.state.threadItems[i + 1].type !== "Comment"
                                    }/>
                  } else {
                    return <EventBubble key={i} threadItem={threadItem}/>
                  }
                })}
              </InfiniteScroll>
            </div>
          </ColumnLayout>
        </div>

      </>
    );
  }
}
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://dky5qea2s5hxfixrguzuactepu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:f2d4a5d8-5e5a-4922-86c1-9580d4179445",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iJKzT7VZc",
    "aws_user_pools_web_client_id": "3smngpdqfvmiqpr1u8u8pv1ae7",
    "oauth": {},
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import React from "react";

import {Button, Form} from '@amzn/awsui-components-react';
import {withRouter} from "react-router-dom";
import GeoConfigPanel from "./GeoConfigPanel";
import {API, graphqlOperation} from "aws-amplify";
import {
  createGlobalConfigItem,
  createPermittedGeo,
  updateGlobalConfigItem,
  updatePermittedGeo
} from "../../graphql/autogen/mutations";
import GlobalConfigPanel from "./GlobalConfigPanel";


const initialFormState = {
  geoChanges: {addGeos: {}, editGeos: {}},
  configChanges: {addItems: [], editItems: {}}
}


class GlobalSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: initialFormState,
      formErrorText: "",
      hasSubmitted: false,
      submitLoading: false,
      geosValid: true,
      configValid: true
    }

    this.submitCallback = this.submitCallback.bind(this)
  }

  async submitCallback() {
    this.setState({hasSubmitted: true, formErrorText: ""})
    if (this.state.geosValid && this.state.configValid) {
      this.setState({submitLoading: true})
      try {
        for (const newId in this.state.formState.geoChanges.addGeos){
          console.log("Pushing new: " + newId +  " " +  this.state.formState.geoChanges.addGeos[newId])
          await API.graphql(graphqlOperation(
            createPermittedGeo,
            {'input': {'id': newId, 'name': this.state.formState.geoChanges.addGeos[newId]}}
          ))
        }
        for (const editedId in this.state.formState.geoChanges.editGeos){
          console.log("Pushing edit: " + editedId +  " " +  this.state.formState.geoChanges.editGeos[editedId])
          await API.graphql(graphqlOperation(
            updatePermittedGeo,
            {'input': {'id': editedId, 'name': this.state.formState.geoChanges.editGeos[editedId]}}
          ))
        }

        for (const newItem of this.state.formState.configChanges.addItems){
          console.log("Pushing property: " + newItem.property +  " with value " + newItem.value)
          const submitItem = {...newItem}
          submitItem.value = Number(submitItem.value)
          await API.graphql(graphqlOperation(createGlobalConfigItem, {'input': submitItem}))
        }
        for (const editedProp in this.state.formState.configChanges.editItems){
          console.log("Pushing edit: " + editedProp +  " " +  this.state.formState.configChanges.editItems[editedProp])
          await API.graphql(graphqlOperation(
            updateGlobalConfigItem,
            {
              'input': {'property': editedProp, 'value': Number(this.state.formState.configChanges.editItems[editedProp])}
            }
          ))
        }

        this.props.history.push('/Dashboard')
      } catch (e) {
        console.log("Error submitting settings changes: ", e)
        this.setState( {formErrorText: e.errors ? e.errors[0].message : e.toString(), submitLoading: false})
      }
    }
  }

  render() {
    return (
      <Form
        header={<h1>Edit Global Settings</h1>}
        errorText={this.state.formErrorText}
        actions={
          // located at the bottom of the form
          <div>
            <Button variant="link" href="#/Dashboard">Cancel</Button>
            <Button onClick={e => this.submitCallback()}
                    loading={this.state.submitLoading}
                    variant="primary">
              Save
            </Button>
          </div>
        }
      >
        <GlobalConfigPanel hasSubmitted={this.state.hasSubmitted}
                           onChange={(details) =>
                             this.setState((prevState) => {
                               return {
                                 formState: {...prevState.formState, configChanges: details.newState},
                                 configValid: details.valid
                               }
                             })
                           }/>
        <GeoConfigPanel hasSubmitted={this.state.hasSubmitted}
                        onChange={(details) =>
                          this.setState((prevState) => {
                            return {
                              formState: {...prevState.formState, geoChanges: details.newState},
                              geosValid: details.valid
                            }
                          })
                        }/>
      </Form>
    )
  }
}

export default withRouter(GlobalSettingsForm);
import React from "react";
import {Alert, AttributeEditor, ColumnLayout, FormSection, Spinner} from "@amzn/awsui-components-react";
import Input from "@amzn/awsui-components-react/polaris/input";
import {v4 as uuidv4} from 'uuid';
import {RequiredInput} from "../FormComponents/RequiredInputs";
import {API, graphqlOperation} from "aws-amplify";
import {listPermittedGeos} from "../../graphql/autogen/queries";

class GeoConfigPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingGeos: true,
      fetchingGeosError: "",
      databaseGeos: new Set(),
      displayedGeos: [],
      addGeos: {},
      editGeos: {},
      invalidIDs: new Set()
    }

    this.definitions = [
      {
        label: () => (<span>Database ID</span>),
        control: (item, index) => {
          return (
            <Input
              value={item.id}
              disabled={true}
            />
          );
        }
      },
      {
        label: () => <span>Geo Name</span>,
        control: (item, index) => {
          return (
            <RequiredInput
              hasSubmitted={this.props.hasSubmitted}
              onInput={e => this.onGeoNameChange(e, index)}
              value={item.name}
              placeholder="Enter a name..."
            />
          );
        }
      }
    ];

    this.fetchCurrentGeos = this.fetchCurrentGeos.bind(this)
    this.pushChanges = this.pushChanges.bind(this)
    this.onAddButtonClickHandler = this.onAddButtonClickHandler.bind(this)
    this.onItemButtonClickHandler = this.onItemButtonClickHandler.bind(this)
    this.onGeoNameChange = this.onGeoNameChange.bind(this)
  }

  async fetchCurrentGeos(){
    this.setState({loadingGeos: true, fetchingGeosError: ""})
    try {
      const geosRes = await API.graphql(graphqlOperation(listPermittedGeos))
      this.setState({
        displayedGeos: [...geosRes.data.listPermittedGeos.items],
        databaseGeos: new Set(geosRes.data.listPermittedGeos.items.map((geo) => geo.id))
      })
    } catch (e) {
      console.log("Error fetching geos: ", e)
      this.setState({fetchingGeosError: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({loadingGeos: false})
    }
  }

  componentDidMount() {
    this.fetchCurrentGeos()
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: {addGeos: this.state.addGeos, editGeos: this.state.editGeos},
        valid: this.state.invalidIDs.size === 0
      })
    }
  }

  onAddButtonClickHandler() {
    this.setState((prevState) => {
      const newId = uuidv4()
      let invalidIDs = new Set(prevState.invalidIDs)
      invalidIDs.add(newId)
      const addGeos = {...prevState.addGeos}
      addGeos[newId] = ''
      return {
        addGeos: addGeos,
        displayedGeos: [...this.state.displayedGeos, {'id': newId, 'name': ''}],
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges());
  }

  onItemButtonClickHandler({detail: {itemIndex}}) {
    const items = this.state.displayedGeos.slice();
    const removedGeo = items.splice(itemIndex, 1)[0];

    this.setState((prevState) => {
      const addGeos = {...prevState.addGeos}
      delete addGeos[removedGeo.id]
      let invalidIDs = new Set(prevState.invalidIDs)
      invalidIDs.delete(removedGeo.id)
      return {
        displayedGeos: items,
        addGeos: addGeos,
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges());
  }

  onGeoNameChange({detail: {value}}, index) {
    this.setState((prevState) => {
      const items = [...prevState.displayedGeos]
      const editedGeos = {...prevState.editGeos}
      const addedGeos = {...prevState.addGeos}
      items[index].name = value
      if (prevState.addGeos[items[index].id] !== undefined){
        addedGeos[items[index].id] = value
      } else {
        editedGeos[items[index].id] = value
      }
      let invalidIDs = new Set(prevState.invalidIDs)
      if (value === "") {
        invalidIDs.add(items[index].id)
      } else {
        invalidIDs.delete(items[index].id)
      }
      return {
        displayedGeos: items,
        addGeos: addedGeos,
        editGeos: editedGeos,
        invalidIDs: invalidIDs
      }
    }, () => this.pushChanges())
  }


  render() {
    return (
      <FormSection header={<h2>Manage Geos</h2>}>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            {this.state.fetchingGeosError ?
              <Alert type="error"
                     header="Error fetching Geos"
                     onButtonClick={this.fetchCurrentGeos}
                     buttonText="Retry">
                {this.state.fetchingGeosError}
              </Alert>
              :
              null
            }
            {this.state.loadingGeos ?
              <div>
                <Spinner/> Loading Geos...
              </div>
              :
              <AttributeEditor
                isItemRemovable={(item) => !this.state.databaseGeos.has(item.id)}
                removeButtonText="Remove"
                addButtonText="Add Geo"
                empty="No Geos Found"
                definition={this.definitions}
                onAddButtonClick={this.onAddButtonClickHandler}
                onRemoveButtonClick={this.onItemButtonClickHandler}
                items={this.state.displayedGeos}
              />
            }
          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}

export default GeoConfigPanel
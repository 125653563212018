import ValidatedInput from "./ValidatedInput";
import {API, graphqlOperation} from "aws-amplify";
import {getOrAddPerson} from "../../graphql/autogen/mutations";

class AliasInput extends ValidatedInput {
  async is_valid(fieldText) {
    if (fieldText === "") {
      if (this.props.required === true) {
        return [false, "Required field"]
      } else {
        return [true, ""]
      }
    }
    try {
      await API.graphql(graphqlOperation(getOrAddPerson, {'alias': fieldText}));
    } catch (e) {
      return [false, "Invalid alias - Doesn't correspond to a known person"];
    }

    return [true, ""]
  }
}

class AliasListInput extends ValidatedInput {
  async is_valid(fieldText) {
    if (fieldText === "") {
      if (this.props.required === true) {
        return [false, "Required field"]
      } else {
        return [true, ""]
      }
    }

    const aliases = fieldText.replace(/\s*,\s*/g, ",").split(",")

    if (aliases.length > 20) {
      return [false, "A maximum of 20 aliases can be specified"]
    }

    for (const alias of aliases) {
      try {
        if (alias !== "") {
          await API.graphql(graphqlOperation(getOrAddPerson, {'alias': alias}))
        }
      } catch (e) {
        return [false, "Invalid alias: " + alias + " - Doesn't correspond to a known person"];
      }
    }

    return [true, ""]
  }
}

export {AliasListInput, AliasInput}
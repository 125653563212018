import Navigation from "../Navigation/Navigation";
import {AppLayout, BreadcrumbGroup, Button} from "@amzn/awsui-components-react";
import React from "react";
import {API, graphqlOperation} from "aws-amplify";
import {
  applyActionAgainstFundingRequest,
  createThreadItem,
  getFundingRequestAuthByPartner
} from "../../graphql/standardAccess";
import FundingRequestSummaryTable from "./FundingRequestSummaryTable";
import FundingRequestDiscussionPanel from "./FundingRequestDiscussionPanel";
import {ParagraphText} from "../FormComponents/TextUtils";
import ConfirmActionModal from "./ActionModal";
import {ErrorAlert, RequestLoading} from "../FormComponents/RequestHelpers";
import CreditCodePanel from "./CreditCodePanel";
import Alert from "@amzn/awsui-components-react/polaris/alert/alert";
import OutcomesModal from "./OutcomesModal";
import {withRouter} from "react-router-dom";
import {parse} from "query-string"


const Breadcrumbs = ({requestName}) => {
  return (
    <BreadcrumbGroup
      items={[
        {
          text: 'BEE Tool',
          href: '#/Dashboard'
        },
        {
          text: 'Funding Requests',
          href: '#/FundingRequest/list'
        },
        {
          text: requestName
        }
      ]}
    />
  )
};


const PageHeader = ({fundingRequest, loading, onRefresh, onAction, isApprover, isEditor, onMarkComplete}) => {
  return (
    <div className="awsui-util-mb-m awsui-util-mt-xs">
      <div className="awsui-util-action-stripe-large">
        <div className="awsui-util-action-stripe-title">
          <h1>{fundingRequest.projectName}</h1>
        </div>
        <div className="awsui-util-action-stripe-group awsui-util-pv-n">
          <Button icon="refresh" loading={loading} onClick={onRefresh}/>
          {isApprover ?
            <>
              <Button
                disabled={fundingRequest.status !== "PendingApproval"}
                icon="status-positive"
                onClick={() => onAction('Approve')}>
                Approve
              </Button>
              <Button
                disabled={fundingRequest.status !== "PendingApproval"}
                icon="status-negative"
                onClick={() => onAction('Reject')}>
                Deny
              </Button>
            </>
            :
            null
          }
          {isEditor ?
            <>
              <Button disabled={fundingRequest.status !== "PendingApproval"}
                      icon="status-negative"
                      onClick={() => onAction('Withdraw')}>
                Withdraw
              </Button>
              {fundingRequest.status === "ProjectInProgress" ?
                <Button icon="status-positive" onClick={onMarkComplete}>Mark Complete</Button>
                :
                null
              }
            </>
            :
            null
          }
        </div>
      </div>
    </div>
  );
};

const RequestDescription = ({fundingRequest, isEditor}) => {
  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>Description</h2>
          </div>
          <div className="awsui-util-action-stripe-group awsui-util-pv-n">
            {isEditor ?
              <Button
                disabled={["ClosedRejected", "ClosedCompleted"].indexOf(fundingRequest.status) !== -1}
                href={`#/FundingRequest/edit/${fundingRequest.id}`}>
                Edit
              </Button>
              :
              null
            }
          </div>
        </div>
      </div>
      <div>
        <ParagraphText text={fundingRequest.description}/>
      </div>
    </div>
  );
}

const ProjectOutcomesPanel = ({fundingRequest, isEditor, onEdit}) => {
  if (fundingRequest.reportedOutcomes) {
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Reported Outcomes</h2>
            </div>
            <div className="awsui-util-action-stripe-group awsui-util-pv-n">
              {isEditor ?
                <Button
                  onClick={onEdit}
                  disabled={["ClosedRejected"].indexOf(fundingRequest.status) !== -1}>
                  Edit
                </Button>
                :
                null
              }
            </div>
          </div>
        </div>
        <div>
          <ParagraphText text={fundingRequest.reportedOutcomes}/>
        </div>
      </div>
    );
  } else {
    return null
  }
}

class ViewRequestPage extends React.Component {
  constructor(props) {
    super(props);

    const activeAction = parse(this.props.location.search).action

    this.state = {
      fundingRequest: null,
      fundingRequestLoading: true,
      fundingRequestErrorText: '',
      commentsErrorText: '',
      submitCommentFieldText: '',
      submitCommentErrorText: '',
      activeAction: activeAction || '',
      modalPreFilledComment: '',
      showOutcomesModal: false
    }

    this.fetchUpdatedFundingRequest = this.fetchUpdatedFundingRequest.bind(this)
    this.submitComment = this.submitComment.bind(this)
    this.submitAction = this.submitAction.bind(this)
  }

  async fetchUpdatedFundingRequest() {
    this.setState({
      fundingRequestLoading: true,
      fundingRequestErrorText: '',
      commentsErrorText: '',
      submitCommentErrorText: ''
    })
    try {
      const itemsRes = await API.graphql(graphqlOperation(
        getFundingRequestAuthByPartner,
        {'id': this.props.match.params.id}
      ))
      this.setState({fundingRequest: itemsRes.data.getFundingRequestAuthByPartner})
    } catch (e) {
      console.log("Error fetching funding request: ", e)
      this.setState({fundingRequestErrorText: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({fundingRequestLoading: false})
    }
  }

  async submitComment() {
    if (this.state.submitCommentFieldText === '') {
      return
    }

    this.setState({submitCommentErrorText: ''})
    try {
      await API.graphql(graphqlOperation(createThreadItem, {
        input: {
          type: 'Comment',
          content: this.state.submitCommentFieldText,
          fundingRequestId: this.state.fundingRequest.id
        }
      }))
      this.setState({submitCommentFieldText: ''})
      await this.fetchUpdatedFundingRequest()
    } catch (e) {
      console.log("Error submitting comment: ", e)
      this.setState({submitCommentErrorText: e.errors ? e.errors[0].message : e.toString()})
    }
  }

  async submitAction(commentText, denominations) {
    const {activeAction} = this.state

    console.log("Submitting:", activeAction, commentText, denominations)

    const variables = {
      fundingRequestId: this.state.fundingRequest.id,
      action: activeAction,
    }
    if (activeAction === "Approve" && denominations) {
      variables['denominationsOverride'] = denominations
    }
    if (commentText) {
      variables['accompanyingComment'] = commentText
      this.setState({submitCommentFieldText: ''})
    }

    const res = await API.graphql(graphqlOperation(applyActionAgainstFundingRequest, variables))
    await this.setState({activeAction: '', fundingRequest: res.data.applyActionAgainstFundingRequest})
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchUpdatedFundingRequest()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const fundingRequestId = this.props.match.params.id
      if (!fundingRequestId) {
        this.setState({fundingRequest: null})
      } else {
        this.fetchUpdatedFundingRequest()
      }
    }

    if (prevProps.location.search !== this.props.location.search){
      const activeAction = parse(this.props.location.search).action
      if (activeAction !== undefined){
        this.setState({activeAction: activeAction})
      }
    }
  }

  render() {
    const currentUsername = this.props.authState.username
    const isPortfolioManager = this.props.authState.groups.indexOf('PortfolioManagers') !== -1
    let isPartnerLeader = false
    if (this.state.fundingRequest) {
      isPartnerLeader |= currentUsername === this.state.fundingRequest.partner.partnerLeaderAlias
      isPartnerLeader |= currentUsername === this.state.fundingRequest.partner.partnerSecondaryAlias
    }

    let isEditor = false
    if (this.state.fundingRequest) {
      isEditor |= currentUsername === this.state.fundingRequest.requestorAlias
      isEditor |= currentUsername === this.state.fundingRequest.projectPSAAlias
      isEditor |= this.state.fundingRequest.additionalContacts && this.state.fundingRequest.additionalContacts.indexOf(currentUsername) !== -1
    }

    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState}/>}
        stickyNotifications={true}
        notifications={<>
          <ErrorAlert
            header={"Error Loading Funding Request"}
            errorText={this.state.fundingRequestErrorText}
            onRetry={this.fetchUpdatedFundingRequest}/>
          <ErrorAlert
            header={"Error loading Funding Request Discussion"}
            errorText={this.state.commentsErrorText}
            onRetry={this.fetchUpdatedFundingRequest}/>
          <ErrorAlert
            header={"Error submitting funding request comment"}
            errorText={this.state.submitCommentErrorText}
            onRetry={this.submitComment}/>
        </>
        }
        breadcrumbs={<Breadcrumbs requestName={this.state.fundingRequest ?
          this.state.fundingRequest.projectName : 'Loading...'
        }/>}
        content={this.state.fundingRequest ?
          <>
            <PageHeader
              fundingRequest={this.state.fundingRequest}
              loading={this.state.fundingRequestLoading}
              onRefresh={this.fetchUpdatedFundingRequest}
              onAction={(action) => this.setState({activeAction: action})}
              isApprover={isPortfolioManager || isPartnerLeader}
              isEditor={isEditor}
              onMarkComplete={() => this.setState({showOutcomesModal: true})}
            />
            {this.state.fundingRequest.status === "OutcomeNeeded" && isEditor ?
              <Alert className="awsui-util-mb-l"
                     type="info"
                     header="Please report outcomes"
                     buttonText="Mark Complete"
                     onButtonClick={() => this.setState({showOutcomesModal: true})}
              >
                Once this project is complete, please mark it as completed and record the project outcomes
              </Alert>
              :
              null
            }
            <FundingRequestSummaryTable fundingRequest={this.state.fundingRequest} isEditor={isEditor}/>
            <RequestDescription fundingRequest={this.state.fundingRequest} isEditor={isEditor}/>
            <ProjectOutcomesPanel fundingRequest={this.state.fundingRequest}
                                  isEditor={isEditor}
                                  onEdit={() => this.setState({showOutcomesModal: true})}
            />
            <CreditCodePanel fundingRequest={this.state.fundingRequest}/>
            <FundingRequestDiscussionPanel
              fundingRequest={this.state.fundingRequest}
              commentFieldText={this.state.submitCommentFieldText}
              onCommentTextChanged={(newText) => this.setState({submitCommentFieldText: newText})}
              onUpdateErrorText={((errorText) => this.setState({commentsErrorText: errorText}))}
              isApprover={isPortfolioManager || isPartnerLeader}
              onPlainCommentSubmit={() => {
                this.submitComment()
              }}
              onCommentWithAction={(preFilledText, action) => this.setState({
                modalPreFilledComment: preFilledText,
                activeAction: action
              })}
            />
            {this.state.activeAction !== '' && this.state.fundingRequest.status === "PendingApproval" ?
              <ConfirmActionModal
                preFilledComment={this.state.modalPreFilledComment}
                fundingRequest={this.state.fundingRequest}
                action={this.state.activeAction}
                onChange={() => this.setState({activeAction: '', modalPreFilledComment: ''})}
                onSubmit={this.submitAction}
              />
              :
              null
            }
            <OutcomesModal visible={this.state.showOutcomesModal}
                           preFilledText={this.state.fundingRequest.reportedOutcomes}
                           fundingRequest={this.state.fundingRequest}
                           onCancel={() => {
                             this.setState({showOutcomesModal: false})
                           }}
                           onSubmit={() => {
                             this.setState({showOutcomesModal: false}, this.fetchUpdatedFundingRequest)
                           }}
            />
          </>
          : <RequestLoading/>
        }
        toolsHide={true}
      />
    )
  }
}

export default withRouter(ViewRequestPage)

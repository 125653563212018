import React from "react";

import {Button, Form} from '@amzn/awsui-components-react';
import {API, graphqlOperation} from "aws-amplify";
import {submitFundingRequest, updateFundingRequest} from "../../graphql/standardAccess";
import RequestDetailsPanel from "./DetailsPanel";
import FundingPanel from "./FundingPanel";
import ProcedureAcknowledgementPanel from "./ProcedureAcknowledgementPanel";
import ProjectTypePanel from "./ProjectTypePanel";
import {withRouter} from "react-router-dom";
import {areDenominationsEqual} from "../FormComponents/RequestHelpers";


const initialFormState = {
  projectName: '',
  partnerId: null,
  geoId: null,
  projectType: 'Build',
  projectPSAAlias: '',
  additionalContacts: [],
  projectDate: null,
  description: '',
  totalRequested: null,
  requestedDenominations: null
}

class FundingRequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: this.props.initialState || initialFormState,
      formErrorText: "",
      hasSubmitted: false,
      submitLoading: false,
      denominationResponse: null,
      requestDetailsValid: !!this.props.initialState,
      fundingDetailsValid: !!this.props.initialState,
      procedureAcknowledgmentsValid: false
    }

    this.requestDetailsPanelRef = React.createRef()
    this.fundingDetailsPanelRef = React.createRef()
    this.procedureAcknowledgmentsPanelRef = React.createRef()

    this.submitCallback = this.submitCallback.bind(this)
    this.saveCallback = this.saveCallback.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.initialState !== this.props.initialState) {
      this.setState({formState: this.props.initialState})
    }
  }

  submitCallback() {
    this.setState({hasSubmitted: true})
    if (this.state.requestDetailsValid && this.state.fundingDetailsValid && this.state.procedureAcknowledgmentsValid) {
      this.setState({submitLoading: true})
      const formState = this.state.formState
      if (formState.requestedDenominations === null){
        delete formState.requestedDenominations
      }

      if (formState.projectDate === ''){
        delete formState.projectDate
      }

      API.graphql(graphqlOperation(submitFundingRequest, {input: formState})).then((result) => {
        this.setState({submitLoading: false})
        const newFundingRequestId = result.data.submitFundingRequest.id
        this.props.history.push(`/FundingRequest/view/${newFundingRequestId}`)
      }).catch((error) => {
        console.log("Error submitting form: ", error)
        let errString = error.errors.map(er => er.errorType + ": " + er.message).join("; \n")
        this.setState({formErrorText: errString, submitLoading: false})
      })
    } else {
      if (!this.state.requestDetailsValid) {
        window.scrollTo({ behavior: 'smooth', top: this.requestDetailsPanelRef.current.offsetTop })
      } else if (!this.state.fundingDetailsValid) {
        window.scrollTo({ behavior: 'smooth', top: this.fundingDetailsPanelRef.current.offsetTop })
      } else if (!this.state.procedureAcknowledgmentsValid) {
        window.scrollTo({ behavior: 'smooth', top: this.procedureAcknowledgmentsPanelRef.current.offsetTop })
      }
    }
  }

  saveCallback() {
    this.setState({hasSubmitted: true, formErrorText: ''})
    if (this.state.requestDetailsValid && this.state.fundingDetailsValid) {
      this.setState({submitLoading: true})
      const formState = this.state.formState

      if (formState.projectDate === ''){
        formState.projectDate = null
      }

      const changedProps = {}
      for (const prop in formState){
        if (formState.hasOwnProperty(prop)){
          if (prop === "requestedDenominations"){
            const oldDenominations = this.props.initialState.requestedDenominations
            const newDenominations = formState.requestedDenominations
            if (!areDenominationsEqual(oldDenominations, newDenominations)){
              changedProps[prop] = formState[prop]
            }
          } else if (prop === "additionalContacts"){
            if (this.props.initialState[prop].toString() !== formState[prop].toString()){
              changedProps[prop] = formState[prop]
            }
          } else if (this.props.initialState[prop] !== formState[prop]){
            changedProps[prop] = formState[prop]
          }
        }
      }

      if (Object.keys(changedProps).length === 0){
        this.props.history.push(`/FundingRequest/view/${this.props.initialState.id}`)
        return
      }

      changedProps.id = this.props.initialState.id
      API.graphql(graphqlOperation(updateFundingRequest, {input: changedProps})).then((result) => {
        this.setState({submitLoading: false})
        this.props.history.push(`/FundingRequest/view/${this.props.initialState.id}`)
      }).catch((error) => {
        console.log("Error submitting form: ", error)
        let errString = error.errors.map(er => er.errorType + ": " + er.message).join("; \n")
        this.setState({formErrorText: errString, submitLoading: false})
      })
    } else {
      if (!this.state.requestDetailsValid) {
        window.scrollTo({ behavior: 'smooth', top: this.requestDetailsPanelRef.current.offsetTop })
      } else if (!this.state.fundingDetailsValid) {
        window.scrollTo({ behavior: 'smooth', top: this.fundingDetailsPanelRef.current.offsetTop })
      }
    }
  }

  render() {
    const {initialState} = this.props

    return (
      <Form
        header={<h1>{this.props.initialState ? <>Edit Funding Request</> : <>Submit Funding Request</>}</h1>}
        errorText={this.state.formErrorText}
        actions={
          // located at the bottom of the form
          <div>
            <Button variant="link"
                    href={
                      this.props.initialState ?
                      "#/FundingRequest/view/" + this.props.initialState.id
                        :
                      "#/FundingRequest/list"
                    }>
              Cancel
            </Button>
            {this.props.initialState ?
              <Button onClick={e => this.saveCallback()}
                      loading={this.state.submitLoading}
                      variant="primary">
                Save
              </Button>
            :
              <Button onClick={e => this.submitCallback()}
                      loading={this.state.submitLoading}
                      variant="primary">
                Submit
              </Button>
            }
          </div>
        }
      >
        <ProjectTypePanel
          initialState={this.props.initialState ? this.props.initialState.projectType : null}
          onChange={e => this.setState(prevState => {
            return {
              formState: {...prevState.formState, ...e.newState}
            }
          })}
        />
        <div ref={this.requestDetailsPanelRef}>
          <RequestDetailsPanel
            initialState={this.props.initialState}
            hasSubmitted={this.state.hasSubmitted}
            onChange={e => this.setState(prevState => {
              return {
                formState: {...prevState.formState, ...e.newState},
                requestDetailsValid: e.valid
              }
            })}
          />
        </div>
        <div ref={this.fundingDetailsPanelRef}>
          <FundingPanel
            initialState={initialState ? {
              totalRequested: initialState.totalRequested,
              requestedDenominations: initialState.requestedDenominations,
              status: initialState.status
            } : null}
            hasSubmitted={this.state.hasSubmitted}
            onChange={e => this.setState(prevState => {
              return {
                formState: {...prevState.formState, ...e.newState},
                fundingDetailsValid: e.valid
              }
            })}
          />
        </div>
        { !this.props.initialState ?
        <div ref={this.procedureAcknowledgmentsPanelRef}>
          <ProcedureAcknowledgementPanel
            hasSubmitted={this.state.hasSubmitted}
            onChange={e => this.setState(prevState => {
              return {
                procedureAcknowledgmentsValid: e.valid
              }
            })}
          />
        </div>
          :
          null
        }
      </Form>
    )
  }
}

export default withRouter(FundingRequestForm);
import React from "react";

const build_expected_outcomes = <>
  <h3>Expected Outcome(s)</h3>
  <ul>
    <li>Usage of AWS managed services (beyond core infra components)</li>
    <li>Drive innovation by enabling partners on new AWS services (private-beta, launches from last 6-months)</li>
    <li>Test early integrations with AWS services in GSI solutions/IP</li>
    <li>Build deeper relationship with AWS service teams</li>
    <li>Test and compare alternate solution approaches, and share feedback with stakeholders</li>
    <li>Promote innovation as part of GSI Launchpads, experience center showcases</li>
  </ul>

  <h3>Measurement criteria</h3>
  <ul>
    <li>Innovative solution approaches, architectures based on new services/features</li>
    <li>Build PoCs, PoVs to share feedback</li>
    <li>Record PFRs to drive AWS services roadmap</li>
    <li>PSA KPI/OKR attainment (Private Beta)</li>
    <li>List of AWS Services integrated in the demo/showcases</li>
    <li>List of customers for whom demo/showcases would be leveraged (ideally track any net-new opportunities)</li>
    <li>Approximate number of customers targeted in GSI Launchpads, experience center/month</li>
  </ul>
</>

const build_whats_not_covered = <>
  <h3>What’s not covered</h3>
  <ul>
    <li>Customer/opportunity specific PoCs/demos should be routed to POA mechanism</li>
    <li>Demos as part of trade-shows, conferences showcasing joint innovation should be routed to MDF mechanism</li>
    <li>Random science projects without clear outcomes will not be funded</li>
    <li>Full solution/IP development should be funded by APN Innovation Sandbox Funding mechanism</li>
    <li>Funding to test older/foundational services (EC2, S3) would not be supported</li>
    <li>Funding for customer to test any AWS services would not be covered here. This is dedicated to support PSA led
      partner activities
    </li>
  </ul>
</>

const build = <div>
  {build_expected_outcomes}
  {build_whats_not_covered}
</div>;

const evangelize_expected_outcomes = <>
  <h3>Expected Outcome(s)</h3>
  <ul>
    <li>Showcase AWS services and features that help partners to be successful in their own environments</li>
    <li>Broad based learning experience that teach practical AWS skills in a gamified manner</li>
    <li>Focus on developing skills in a targeted area (Migrations, Security, DevOps, Networking, Microservices)</li>
    <li>Promote partners to do TMA type of showcases</li>
  </ul>

  <h3>Measurement criteria</h3>
  <ul>
    <li>Track total number of participants, CSAT and overall feedback (summarized in trip report)</li>
    <li>Report on any new solutions, PoCs that the event might initiate</li>
    <li>Track any net-new AWS certifications</li>
    <li>PSA KPI/OKR attainment (enablement)</li>
    <li>Views & feedback on TMA type of external content</li>
  </ul>
</>

const evangelize_whats_not_covered = <>
  <h3>What’s not covered</h3>
  <ul>
    <li>Direct customer facing campaigns, roadshows should be covered by MDF mechanism</li>
    <li>Evangelism initiatives without hands-on activity/demos/showcases will not be funded</li>
    <li>Customer facing assessment or workshops will not be supported, as these should under POA</li>
  </ul>
</>

const evangelize = <div>
  {evangelize_expected_outcomes}
  {evangelize_whats_not_covered}
</div>;

const enable_expected_outcomes = <>
  <h3>Expected Outcome(s)</h3>
  <ul>
    <li>Drive immersive AWS experience, which can include multiple modules and hands-on immersion labs</li>
    <li>Drive enablement for an account team, horizontal/vertical focused partner team</li>
    <li>Build deep skills in a specific area (like AWS architecture, security, big-data/analytics, machine learning
      etc.)
    </li>
    <li>Can be a multi-week coaching activity to drive deeper engagement and certifications</li>
  </ul>

  <h3>Measurement criteria</h3>
  <ul>
    <li>Track total number of participants, CSAT and overall feedback (summarized in trip report)</li>
    <li>Report on any new solutions, PoCs that the event might initiate</li>
    <li>Track any net-new AWS certifications</li>
    <li>Track total number of AWS champions created and their account mapping (if possible)</li>
    <li>Net-new customer opportunities influenced, solutions/PoCs created by certified resources</li>
    <li>PSA KPI/OKR attainment (enablement)</li>
  </ul>
</>

const enable_whats_not_covered = <>
  <h3>What’s not covered</h3>
  <ul>
    <li>Direct enablement at an account, where partner is not involved is not supported</li>
    <li>Enablement initiatives without hands-on activity will not be funded</li>
    <li>Funding should not be used to just encourage session participation</li>
  </ul>
</>

const enable = <div>
  {enable_expected_outcomes}
  {enable_whats_not_covered}
</div>;

export {build, enable, evangelize, build_whats_not_covered, build_expected_outcomes, enable_expected_outcomes,
  enable_whats_not_covered, evangelize_whats_not_covered, evangelize_expected_outcomes};
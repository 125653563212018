export const listFundingAppsByStatus = /* GraphQL */ `
    query ListFundingApps ($status: FundingStatus!) {
        listFundingRequests(filter: {status: {eq: $status}}, limit: 1000) {
            items {
                id
            }
            nextToken
        }
    }
`

export const listFundingAppsInProgress = /* GraphQL */ `
    query ListFundingApps {
    listFundingRequests(
        filter: {or: [{status: {eq: ApprovedPendingCodes}}, {status: {eq: ProjectInProgress}}]}, 
        limit: 1000
    ) {
        items {
            id
        }
        nextToken
    }
}
`


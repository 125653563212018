import React from "react";
import {Alert, AttributeEditor, ColumnLayout, FormSection, Spinner} from "@amzn/awsui-components-react";
import {RequiredInput} from "../FormComponents/RequiredInputs";
import {API, graphqlOperation} from "aws-amplify";
import {listGlobalConfigItems} from "../../graphql/autogen/queries";
import ValidatedInput from "../FormComponents/ValidatedInput";

class IntegerInput extends ValidatedInput {
  async is_valid(fieldText) {
    if (fieldText === "") {
      return [false, "Required field"]
    }

    const numeric = Number(fieldText)
    if (isNaN(numeric)) {
      return [false, "Not a number"]
    }

    if (numeric % 1 !== 0) {
      return [false, "Must be an integer"]
    }

    return [true, ""]
  }
}


class GlobalConfigPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingConfig: true,
      fetchingConfigError: "",
      databaseConfigItems: new Set(),
      displayedConfigItems: [],
      addConfigItems: [],
      editConfigItems: {},
      invalidPropertyIndexes: new Set(),
      invalidValueIndexes: new Set()
    }

    this.definitions = [
      {
        label: () => (<span>Property</span>),
        control: (item, index) => {
          return (
            <RequiredInput
              value={item.property}
              hasSubmitted={this.props.hasSubmitted}
              disabled={this.state.databaseConfigItems.has(item.property)}
              onInput={e => this.onConfigItemPropertyChange(e, index)}
              placeholder="Enter a property..."
            />
          );
        }
      },
      {
        label: () => <span>Value</span>,
        control: (item, index) => {
          return (
            <IntegerInput
              type={"number"}
              hasSubmitted={this.props.hasSubmitted}
              onInput={e => this.onConfigItemValueChange(e, index)}
              value={item.value.toString()}
              placeholder="Enter a value..."
            />
          );
        }
      }
    ];

    this.fetchCurrentConfigItems = this.fetchCurrentConfigItems.bind(this)
    this.pushChanges = this.pushChanges.bind(this)
    this.onAddButtonClickHandler = this.onAddButtonClickHandler.bind(this)
    this.onItemButtonClickHandler = this.onItemButtonClickHandler.bind(this)
    this.onConfigItemValueChange = this.onConfigItemValueChange.bind(this)
    // this.onConfigItemPropertyChange = this.onConfigItemPropertyChange.bind(this)
  }

  async fetchCurrentConfigItems(){
    this.setState({loadingConfig: true, fetchingConfigError: ""})
    try {
      const configRes = await API.graphql(graphqlOperation(listGlobalConfigItems))
      this.setState({
        displayedConfigItems: [...configRes.data.listGlobalConfigItems.items],
        databaseConfigItems: new Set(configRes.data.listGlobalConfigItems.items.map((configItem) => configItem.property))
      })
    } catch (e) {
      console.log("Error fetching global config: ", e)
      this.setState({fetchingConfigError: e.errors ? e.errors[0].message : e.toString()})
    } finally {
      this.setState({loadingConfig: false})
    }
  }

  componentDidMount() {
    this.fetchCurrentConfigItems()
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: {addItems: this.state.addConfigItems, editItems: this.state.editConfigItems},
        valid: this.state.invalidValueIndexes.size === 0 && this.state.invalidPropertyIndexes.size === 0
      })
    }
  }

  onAddButtonClickHandler() {
    this.setState((prevState) => {
      let invalidValueIndexes = new Set(prevState.invalidValueIndexes)
      let invalidPropertyIndexes = new Set(prevState.invalidPropertyIndexes)
      invalidValueIndexes.add(prevState.displayedConfigItems.length)
      invalidPropertyIndexes.add(prevState.displayedConfigItems.length)
      return {
        addConfigItems: [...prevState.addConfigItems, {'property': '', 'value': ''}],
        displayedConfigItems: [...this.state.displayedConfigItems, {'property': '', 'value': ''}],
        invalidValueIndexes: invalidValueIndexes,
        invalidPropertyIndexes: invalidPropertyIndexes
      }
    }, () => this.pushChanges());
  }

  onItemButtonClickHandler({detail: {itemIndex}}) {
    this.setState((prevState) => {
      const items = prevState.displayedConfigItems.slice();
      items.splice(itemIndex, 1)

      const addItems = prevState.addConfigItems.slice()
      addItems.splice(itemIndex - this.state.databaseConfigItems.size, 1)

      let invalidValueIndexes = new Set()
      let invalidPropertyIndexes = new Set()
      for (const item of prevState.invalidValueIndexes){
        if (item !== itemIndex){
          if (item > itemIndex){
            invalidValueIndexes.add(item - 1)
          } else {
            invalidValueIndexes.add(item)
          }
        }
      }
      for (const item of prevState.invalidPropertyIndexes){
        if (item !== itemIndex){
          if (item > itemIndex){
            invalidPropertyIndexes.add(item - 1)
          } else {
            invalidPropertyIndexes.add(item)
          }
        }
      }

      return {
        displayedConfigItems: items,
        addConfigItems: addItems,
        invalidValueIndexes: invalidValueIndexes,
        invalidPropertyIndexes: invalidPropertyIndexes
      }
    }, () => this.pushChanges());
  }

  onConfigItemValueChange({detail: {value, valid}}, index) {
    this.setState((prevState) => {
      const items = [...prevState.displayedConfigItems]
      const editedItems = {...prevState.editConfigItems}
      const addedItems = [...prevState.addConfigItems]
      items[index].value = value
      if (this.state.databaseConfigItems.has(items[index].property)){
        editedItems[items[index].property] = value
      } else {
        addedItems[index - this.state.databaseConfigItems.size].value = value
      }
      let invalidValueIndexes = new Set(prevState.invalidValueIndexes)
      if (valid) {
        invalidValueIndexes.delete(index)
      } else {
        invalidValueIndexes.add(index)
      }
      return {
        displayedConfigItems: items,
        addConfigItems: addedItems,
        editConfigItems: editedItems,
        invalidValueIndexes: invalidValueIndexes
      }
    }, () => this.pushChanges())
  }

  onConfigItemPropertyChange({detail: {value, valid}}, index) {
    this.setState((prevState) => {
      const items = [...prevState.displayedConfigItems]
      const addedItems = [...prevState.addConfigItems]
      items[index] = {...items[index]}
      items[index].property = value

      addedItems[index - this.state.databaseConfigItems.size].property = value

      let invalidPropertyIndexes = new Set(prevState.invalidPropertyIndexes)
      if (valid) {
        invalidPropertyIndexes.delete(index)
      } else {
        invalidPropertyIndexes.add(index)
      }
      return {
        displayedConfigItems: items,
        addConfigItems: addedItems,
        invalidPropertyIndexes: invalidPropertyIndexes
      }
    }, () => this.pushChanges())
  }

  async callbackabc(){
    return []
  }


  render() {
    return (
      <FormSection header={<h2>Manage Global Parameters</h2>}>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            {this.state.fetchingConfigError ?
              <Alert type="error"
                     header="Error fetching parameters"
                     onButtonClick={this.fetchCurrentConfigItems}
                     buttonText="Retry">
                {this.state.fetchingConfigError}
              </Alert>
              :
              null
            }
            {this.state.loadingConfig ?
              <div>
                <Spinner/> Loading Parameters...
              </div>
              :
              <AttributeEditor
                isItemRemovable={(item) => !this.state.databaseConfigItems.has(item.property)}
                removeButtonText="Remove"
                addButtonText="Add Parameter"
                empty="No parameters Found"
                definition={this.definitions}
                onAddButtonClick={this.onAddButtonClickHandler}
                onRemoveButtonClick={this.onItemButtonClickHandler}
                items={this.state.displayedConfigItems}
              />
            }
          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}

export default GlobalConfigPanel
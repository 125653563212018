import React from "react";
import {Checkbox, ColumnLayout, FormSection} from "@amzn/awsui-components-react";
import Icon from "@amzn/awsui-components-react/polaris/icon";

class RequiredCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {checked: false}
  }

  render() {
    const invalid = this.props.hasSubmitted === true && this.state.checked === false
    return (
      <div>
        <Checkbox {...this.props} checked={this.state.checked} onChange={e => {
          this.setState({checked: e.detail.checked})
          if (this.props.hasOwnProperty("onChange")) {
            this.props.onChange(e)
          }
        }}>
          {this.props.message}
        </Checkbox>
        {invalid ?
          <span className="error-text awsui-util-pt-xs display-block awsui-form-field-description">
            <Icon name="status-warning"/> Please use the checkbox to acknowledge the given statement</span> : null}
      </div>
    )
  }
}

class ProcedureAcknowledgementPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      criteriaAcknowledgement: false,
      doubleDippingAcknowledgement: false
    }

    this.pushChanges = this.pushChanges.bind(this)
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: this.state,
        valid: this.state.criteriaAcknowledgement && this.state.doubleDippingAcknowledgement
      })
    }
  }

  render() {
    return (
      <FormSection header={<h2>Procedures Acknowledgment</h2>}>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            <RequiredCheckBox
              message={"I understand the expected outcomes and measurement criteria for this project " +
              "(as listed above) and will record them once the project is complete"}
              hasSubmitted={this.props.hasSubmitted}
              onChange={e => {
                this.setState({criteriaAcknowledgement: e.detail.checked}, () => this.pushChanges());

              }}
            />
            <RequiredCheckBox
              message="I confirm that I have not received (and will not receive) funding for this project through any other funding source"
              hasSubmitted={this.props.hasSubmitted}
              onChange={e => {
                this.setState({doubleDippingAcknowledgement: e.detail.checked}, () => this.pushChanges());
              }}
            />
          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}

export default ProcedureAcknowledgementPanel

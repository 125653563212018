import React from "react";
import {ColumnLayout, DatePicker, FormField, FormSection} from "@amzn/awsui-components-react";
import {RequiredInput, RequiredTextArea} from "../FormComponents/RequiredInputs";
import GraphQLModelBackedSelect from "../FormComponents/GraphQLModelBackedSelect";
import {listPartners} from "../../graphql/standardAccess";
import {listPermittedGeos} from "../../graphql/autogen/queries";
import {AliasInput, AliasListInput} from "../FormComponents/AliasInputs";


class RequestDetailsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      partnerId: null,
      geoId: null,
      projectPSAAlias: '',
      projectPSAAliasIsValid: false,
      additionalContacts: '',
      additionalContactsIsValid: true,
      projectDate: '',
      description: '',
    }

    const {initialState} = props

    if (initialState) {
      for (const prop in this.state) {
        if (this.state.hasOwnProperty(prop) && initialState.hasOwnProperty(prop)) {
          if (prop === "additionalContacts") {
            this.state.additionalContacts = initialState.additionalContacts ? initialState.additionalContacts.join(', ') : ""
          } else if (prop === "projectPSAAlias"){
            this.state.projectPSAAlias = initialState.projectPSAAlias
            this.state.projectPSAAliasIsValid = true
          } else {
            this.state[prop] = initialState[prop] || ''
          }
        }
      }
    }

    this.pushChanges = this.pushChanges.bind(this)
  }

  pushChanges() {
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange({
        newState: {
          projectName: this.state.projectName,
          partnerId: this.state.partnerId,
          geoId: this.state.geoId,
          projectPSAAlias: this.state.projectPSAAlias,
          additionalContacts: this.state.additionalContacts.replace(/\s*,\s*/g, ",").split(",").filter(c => c !== ""),
          projectDate: this.state.projectDate,
          description: this.state.description
        },
        valid: (
          this.state.projectName !== ''
          && this.state.partnerId !== null
          && this.state.geoId !== null
          && this.state.description !== ''
          && this.state.projectPSAAliasIsValid
          && this.state.additionalContactsIsValid
        )
      })
    }
  }

  render() {
    const {initialState} = this.props
    const initialPartnerSelectOption = this.props.initialState ? {
      id: initialState.partnerId, label: initialState.partner.name
    } : null
    const initialRegionSelectOption = this.props.initialState ? {
      id: initialState.geoId, label: initialState.geo.name
    } : null

    return (
      <FormSection header={<h2>Request Information</h2>}>
        <ColumnLayout>
          <div data-awsui-column-layout-root={true}>
            <FormField
              label={
                <span>
                  Project Name
                </span>
              }
              description={"A short description to concisely identify this request"}
            >
              <RequiredInput value={this.state.projectName}
                             hasSubmitted={this.props.hasSubmitted}
                             placeholder={"Partner ML/AI Skills Workshop Day"}
                             onChange={e => {
                               this.setState({projectName: e.detail.value}, () => this.pushChanges())
                             }}/>
            </FormField>

            <FormField
              label={"Partner"}
              description="The partner that will be supported by this project"
            >
              <GraphQLModelBackedSelect initialState={initialPartnerSelectOption}
                                        listQuery={listPartners}
                                        disabled={
                                          !!this.props.initialState &&
                                          this.props.initialState.status !== "PendingApproval"
                                        }
                                        listQueryResponseField="listPartners"
                                        idField="id"
                                        labelField="name"
                                        placeholder="Select a partner"
                                        loadingText="Loading Partners"
                                        errorText="Error fetching partners."
                                        onChange={e => {
                                          this.setState({partnerId: e.detail.selectedId}, () => this.pushChanges())
                                        }}
                                        required={true}
                                        hasSubmitted={this.props.hasSubmitted}/>
            </FormField>

            <FormField
              label={"Region"}
              description="The primary region that this project will take place in"
            >
              <GraphQLModelBackedSelect initialState={initialRegionSelectOption}
                                        listQuery={listPermittedGeos}
                                        listQueryResponseField="listPermittedGeos"
                                        idField="id"
                                        labelField="name"
                                        placeholder="Select a region"
                                        loadingText="Loading Regions"
                                        errorText="Error fetching regions."
                                        onChange={e => {
                                          this.setState({geoId: e.detail.selectedId}, () => this.pushChanges())
                                        }}
                                        required={true}
                                        hasSubmitted={this.props.hasSubmitted}/>
            </FormField>
            <FormField
              label={"Project PSA"}
              description={"The PSA who will conduct this initiative with the partner"}
            >
              <AliasInput value={initialState ? initialState.projectPSAAlias : ""}
                          placeholder={"PSA alias"}
                          hasSubmitted={this.props.hasSubmitted}
                          required={true}
                          onInput={e => {
                            this.setState({
                              projectPSAAlias: e.detail.value,
                              projectPSAAliasIsValid: e.detail.valid
                            }, () => this.pushChanges())
                          }}/>
            </FormField>

            <FormField label={<span>Additional Contacts <i> - optional</i></span>}
                       description={"Additional contacts will be able to view and modify the details of this request and will be copied on all communications"}
                       hintText={"Enter up to 20 additional aliases separated by commas"}
            >
              <AliasListInput value={initialState && initialState.additionalContacts ? initialState.additionalContacts.join(", ") : ""}
                              placeholder={"alias1, alias2..."}
                              hasSubmitted={this.props.hasSubmitted}
                              onInput={e => {
                                this.setState({
                                  additionalContacts: e.detail.value,
                                  additionalContactsIsValid: e.detail.valid
                                }, () => this.pushChanges())
                              }}
              />
            </FormField>
            <FormField
              label={
                <span>Project Date <i> - optional</i></span>
              }
              description={"The approximate date when the project will be complete"}
            >
              <DatePicker value={this.state.projectDate}
                          disabled={ !!this.props.initialState &&
                            ["PendingApproval", "ApprovedPendingCodes"].indexOf(this.props.initialState.status) === -1
                          }
                          placeholder="YYYY/MM/DD"
                          todayLabel="Today"
                          nextMonthLabel="Next month"
                          previousMonthLabel="Previous month"
                          onChange={e => this.setState({projectDate: e.detail.value}, () => this.pushChanges())}
              />
            </FormField>

            <FormField
              label={"Detailed Request Description"}
              description={"A detailed description of your request, including the approximate number of participants and expected outcomes"}
            >
              <RequiredTextArea
                value={this.state.description}
                placeholder={"Describe the request..."}
                hasSubmitted={this.props.hasSubmitted}
                onChange={e => {
                  this.setState({description: e.detail.value}, () => this.pushChanges())
                }}
              />
            </FormField>

          </div>
        </ColumnLayout>
      </FormSection>
    )
  }
}

export default RequestDetailsPanel
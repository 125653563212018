/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDenominationsForTotal = /* GraphQL */ `
  query GetDenominationsForTotal($total: Int!) {
    getDenominationsForTotal(total: $total) {
      isAvailable
      denominations {
        value
        quantity
      }
      problems {
        denominationValue
        requestedQuantity
      }
    }
  }
`;
export const isDenominationSetAvailable = /* GraphQL */ `
  query IsDenominationSetAvailable($denominations: [DenominationInput]!) {
    isDenominationSetAvailable(denominations: $denominations) {
      isAvailable
      denominations {
        value
        quantity
      }
      problems {
        denominationValue
        requestedQuantity
      }
    }
  }
`;
export const isApprover = /* GraphQL */ `
  query IsApprover($alias: String!) {
    isApprover(alias: $alias)
  }
`;
export const decryptCreditCode = /* GraphQL */ `
  query DecryptCreditCode($creditCodeID: ID!) {
    decryptCreditCode(creditCodeID: $creditCodeID)
  }
`;
export const getFundingRequestAuthByPartner = /* GraphQL */ `
  query GetFundingRequestAuthByPartner($id: ID!) {
    getFundingRequestAuthByPartner(id: $id) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests {
          items {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      description
      assignedCreditCodes {
        items {
          id
          code
          denomination
          status
          assignedToRequestId
          createdAt
          updatedAt
        }
        nextToken
      }
      discussionThread {
        items {
          id
          fromAlias
          from {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          type
          createdAt
          fundingRequestId
          fundingRequest {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          actionType
          modifiedProp
          newVal
          content
          associatedWithAction
        }
        nextToken
      }
      reportedOutcomes
      submittedAt
      lastUpdated
      alwaysSeven
    }
  }
`;
export const getCreditCode = /* GraphQL */ `
  query GetCreditCode($id: ID!) {
    getCreditCode(id: $id) {
      id
      code
      denomination
      status
      assignedToRequestId
      createdAt
      updatedAt
    }
  }
`;
export const listCreditCodes = /* GraphQL */ `
  query ListCreditCodes(
    $id: ID
    $filter: ModelCreditCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreditCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        code
        denomination
        status
        assignedToRequestId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCreditCodeDenomination = /* GraphQL */ `
  query GetCreditCodeDenomination($value: Int!) {
    getCreditCodeDenomination(value: $value) {
      value
      totalQuantity
      availableQuantity
      createdAt
      updatedAt
    }
  }
`;
export const listCreditCodeDenominations = /* GraphQL */ `
  query ListCreditCodeDenominations(
    $value: Int
    $filter: ModelCreditCodeDenominationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreditCodeDenominations(
      value: $value
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        value
        totalQuantity
        availableQuantity
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFundingRequest = /* GraphQL */ `
  query GetFundingRequest($id: ID!) {
    getFundingRequest(id: $id) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests {
          items {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      description
      assignedCreditCodes {
        items {
          id
          code
          denomination
          status
          assignedToRequestId
          createdAt
          updatedAt
        }
        nextToken
      }
      discussionThread {
        items {
          id
          fromAlias
          from {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          type
          createdAt
          fundingRequestId
          fundingRequest {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          actionType
          modifiedProp
          newVal
          content
          associatedWithAction
        }
        nextToken
      }
      reportedOutcomes
      submittedAt
      lastUpdated
      alwaysSeven
    }
  }
`;
export const listFundingRequests = /* GraphQL */ `
  query ListFundingRequests(
    $id: ID
    $filter: ModelFundingRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFundingRequests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        stepFunctionExecutionARN
        stepFunctionCallbackToken
        status
        projectName
        partnerId
        partner {
          id
          name
          partnerLeaderAlias
          partnerLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          partnerSecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          allocatedBudget
          consumedBudget
          fundingRequests {
            items {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              totalRequested
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geoId
        geo {
          id
          name
          createdAt
          updatedAt
        }
        projectType
        projectDate
        requestorAlias
        requestor {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        projectPSAAlias
        projectPSA {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        additionalContacts
        totalRequested
        requestedDenominations {
          value
          quantity
        }
        description
        assignedCreditCodes {
          items {
            id
            code
            denomination
            status
            assignedToRequestId
            createdAt
            updatedAt
          }
          nextToken
        }
        discussionThread {
          items {
            id
            fromAlias
            from {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            type
            createdAt
            fundingRequestId
            fundingRequest {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              totalRequested
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            actionType
            modifiedProp
            newVal
            content
            associatedWithAction
          }
          nextToken
        }
        reportedOutcomes
        submittedAt
        lastUpdated
        alwaysSeven
      }
      nextToken
    }
  }
`;
export const getPermittedGeo = /* GraphQL */ `
  query GetPermittedGeo($id: ID!) {
    getPermittedGeo(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listPermittedGeos = /* GraphQL */ `
  query ListPermittedGeos(
    $filter: ModelPermittedGeoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermittedGeos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlobalConfigItem = /* GraphQL */ `
  query GetGlobalConfigItem($property: String!) {
    getGlobalConfigItem(property: $property) {
      property
      value
      createdAt
      updatedAt
    }
  }
`;
export const listGlobalConfigItems = /* GraphQL */ `
  query ListGlobalConfigItems(
    $property: String
    $filter: ModelGlobalConfigItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGlobalConfigItems(
      property: $property
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        property
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($alias: String!) {
    getPerson(alias: $alias) {
      alias
      fullName
      email
      createdAt
      updatedAt
    }
  }
`;
export const listPersons = /* GraphQL */ `
  query ListPersons(
    $alias: String
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPersons(
      alias: $alias
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      fundingRequests {
        items {
          id
          stepFunctionExecutionARN
          stepFunctionCallbackToken
          status
          projectName
          partnerId
          partner {
            id
            name
            partnerLeaderAlias
            partnerLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            partnerSecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            allocatedBudget
            consumedBudget
            fundingRequests {
              nextToken
            }
            createdAt
            updatedAt
          }
          geoId
          geo {
            id
            name
            createdAt
            updatedAt
          }
          projectType
          projectDate
          requestorAlias
          requestor {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          projectPSAAlias
          projectPSA {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          additionalContacts
          totalRequested
          requestedDenominations {
            value
            quantity
          }
          description
          assignedCreditCodes {
            items {
              id
              code
              denomination
              status
              assignedToRequestId
              createdAt
              updatedAt
            }
            nextToken
          }
          discussionThread {
            items {
              id
              fromAlias
              type
              createdAt
              fundingRequestId
              actionType
              modifiedProp
              newVal
              content
              associatedWithAction
            }
            nextToken
          }
          reportedOutcomes
          submittedAt
          lastUpdated
          alwaysSeven
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $id: ID
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPartners(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests {
          items {
            id
            stepFunctionExecutionARN
            stepFunctionCallbackToken
            status
            projectName
            partnerId
            partner {
              id
              name
              partnerLeaderAlias
              partnerSecondaryAlias
              allocatedBudget
              consumedBudget
              createdAt
              updatedAt
            }
            geoId
            geo {
              id
              name
              createdAt
              updatedAt
            }
            projectType
            projectDate
            requestorAlias
            requestor {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            projectPSAAlias
            projectPSA {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            additionalContacts
            totalRequested
            requestedDenominations {
              value
              quantity
            }
            description
            assignedCreditCodes {
              nextToken
            }
            discussionThread {
              nextToken
            }
            reportedOutcomes
            submittedAt
            lastUpdated
            alwaysSeven
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCreditCodesWithDenomination = /* GraphQL */ `
  query ListCreditCodesWithDenomination(
    $denomination: Int
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditCodesWithDenomination(
      denomination: $denomination
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        denomination
        status
        assignedToRequestId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCreditCodesWithStatus = /* GraphQL */ `
  query ListCreditCodesWithStatus(
    $status: CodeStatus
    $denomination: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditCodesWithStatus(
      status: $status
      denomination: $denomination
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        denomination
        status
        assignedToRequestId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFundingRequestsSortBySubmitted = /* GraphQL */ `
  query ListFundingRequestsSortBySubmitted(
    $alwaysSeven: Int
    $submittedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFundingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFundingRequestsSortBySubmitted(
      alwaysSeven: $alwaysSeven
      submittedAt: $submittedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stepFunctionExecutionARN
        stepFunctionCallbackToken
        status
        projectName
        partnerId
        partner {
          id
          name
          partnerLeaderAlias
          partnerLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          partnerSecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          allocatedBudget
          consumedBudget
          fundingRequests {
            items {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              totalRequested
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geoId
        geo {
          id
          name
          createdAt
          updatedAt
        }
        projectType
        projectDate
        requestorAlias
        requestor {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        projectPSAAlias
        projectPSA {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        additionalContacts
        totalRequested
        requestedDenominations {
          value
          quantity
        }
        description
        assignedCreditCodes {
          items {
            id
            code
            denomination
            status
            assignedToRequestId
            createdAt
            updatedAt
          }
          nextToken
        }
        discussionThread {
          items {
            id
            fromAlias
            from {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            type
            createdAt
            fundingRequestId
            fundingRequest {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              totalRequested
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            actionType
            modifiedProp
            newVal
            content
            associatedWithAction
          }
          nextToken
        }
        reportedOutcomes
        submittedAt
        lastUpdated
        alwaysSeven
      }
      nextToken
    }
  }
`;
export const listFundingRequestsByPartner = /* GraphQL */ `
  query ListFundingRequestsByPartner(
    $partnerId: ID
    $submittedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFundingRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFundingRequestsByPartner(
      partnerId: $partnerId
      submittedAt: $submittedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stepFunctionExecutionARN
        stepFunctionCallbackToken
        status
        projectName
        partnerId
        partner {
          id
          name
          partnerLeaderAlias
          partnerLeader {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          partnerSecondaryAlias
          partnerSecondary {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          allocatedBudget
          consumedBudget
          fundingRequests {
            items {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              totalRequested
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geoId
        geo {
          id
          name
          createdAt
          updatedAt
        }
        projectType
        projectDate
        requestorAlias
        requestor {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        projectPSAAlias
        projectPSA {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        additionalContacts
        totalRequested
        requestedDenominations {
          value
          quantity
        }
        description
        assignedCreditCodes {
          items {
            id
            code
            denomination
            status
            assignedToRequestId
            createdAt
            updatedAt
          }
          nextToken
        }
        discussionThread {
          items {
            id
            fromAlias
            from {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            type
            createdAt
            fundingRequestId
            fundingRequest {
              id
              stepFunctionExecutionARN
              stepFunctionCallbackToken
              status
              projectName
              partnerId
              geoId
              projectType
              projectDate
              requestorAlias
              projectPSAAlias
              additionalContacts
              totalRequested
              description
              reportedOutcomes
              submittedAt
              lastUpdated
              alwaysSeven
            }
            actionType
            modifiedProp
            newVal
            content
            associatedWithAction
          }
          nextToken
        }
        reportedOutcomes
        submittedAt
        lastUpdated
        alwaysSeven
      }
      nextToken
    }
  }
`;
export const listThreadItemsByFundingRequest = /* GraphQL */ `
  query ListThreadItemsByFundingRequest(
    $fundingRequestId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelThreadItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadItemsByFundingRequest(
      fundingRequestId: $fundingRequestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromAlias
        from {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        type
        createdAt
        fundingRequestId
        fundingRequest {
          id
          stepFunctionExecutionARN
          stepFunctionCallbackToken
          status
          projectName
          partnerId
          partner {
            id
            name
            partnerLeaderAlias
            partnerLeader {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            partnerSecondaryAlias
            partnerSecondary {
              alias
              fullName
              email
              createdAt
              updatedAt
            }
            allocatedBudget
            consumedBudget
            fundingRequests {
              nextToken
            }
            createdAt
            updatedAt
          }
          geoId
          geo {
            id
            name
            createdAt
            updatedAt
          }
          projectType
          projectDate
          requestorAlias
          requestor {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          projectPSAAlias
          projectPSA {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          additionalContacts
          totalRequested
          requestedDenominations {
            value
            quantity
          }
          description
          assignedCreditCodes {
            items {
              id
              code
              denomination
              status
              assignedToRequestId
              createdAt
              updatedAt
            }
            nextToken
          }
          discussionThread {
            items {
              id
              fromAlias
              type
              createdAt
              fundingRequestId
              actionType
              modifiedProp
              newVal
              content
              associatedWithAction
            }
            nextToken
          }
          reportedOutcomes
          submittedAt
          lastUpdated
          alwaysSeven
        }
        actionType
        modifiedProp
        newVal
        content
        associatedWithAction
      }
      nextToken
    }
  }
`;

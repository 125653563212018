import React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import {ColumnLayout} from "@amzn/awsui-components-react";
import {API, graphqlOperation} from "aws-amplify";
import {listFundingAppsByStatus, listFundingAppsInProgress,} from "../../graphql/fundingRequestStatusQueries";
import {
  countFundingRequestsForMyPartnersByStatus,
  countInProgressFundingRequestsForMyPartners
} from "../../graphql/psaLeaderAccess";
import {dataScopeDisclaimer} from "../FormComponents/RequestHelpers";

export class FundingRequestDashPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingCount: null,
      inProgressCount: null,
      outcomeNeededCount: null,
      completeCount: null
    }

    this.updateCounts = this.updateCounts.bind(this)
  }

  async updateCounts() {
    try {
      const pendingRes = await API.graphql(
        graphqlOperation(listFundingAppsByStatus, {status: "PendingApproval"})
      );
      const inProgressRes = await API.graphql(
        graphqlOperation(listFundingAppsInProgress)
      );
      const outcomeNeededRes = await API.graphql(graphqlOperation(
        listFundingAppsByStatus, {status: "OutcomeNeeded"})
      );
      const completeCountRes = await API.graphql(
        graphqlOperation(listFundingAppsByStatus, {status: "ClosedCompleted"})
      );

      const partnerCounts = {
        partnerPendingRes: 0,
        partnerInProgressRes: 0,
        partnerOutcomeNeededRes: 0,
        partnerCompleteCountRes: 0
      }
      if (this.props.isPartnerLeader && !this.props.isPortfolioManager) {
        const partnerPendingRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersByStatus,
            {leader: this.props.username, status: "PendingApproval"})
        );
        const partnerInProgressRes = await API.graphql(
          graphqlOperation(
            countInProgressFundingRequestsForMyPartners,
            {leader: this.props.username})
        );
        const partnerOutcomeNeededRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersByStatus,
            {leader: this.props.username, status: "OutcomeNeeded"})
        );
        const partnerCompleteCountRes = await API.graphql(
          graphqlOperation(
            countFundingRequestsForMyPartnersByStatus,
            {leader: this.props.username, status: "ClosedCompleted"})
        );
        const partnerResults = {
          partnerPendingRes,
          partnerInProgressRes,
          partnerOutcomeNeededRes,
          partnerCompleteCountRes
        }

        for (const resultType in partnerResults) {
          const result = partnerResults[resultType]
          partnerCounts[resultType] = result.data.listPartners.items.map(
            (partner) => partner.fundingRequests.nextToken ? 1000 : partner.fundingRequests.items.length
          ).reduce((accum, curVal) => accum + curVal)
        }
      }

      const pendingCount = pendingRes.data.listFundingRequests.items.length
      const inProgressCount = inProgressRes.data.listFundingRequests.items.length
      const outcomeNeededCount = outcomeNeededRes.data.listFundingRequests.items.length
      const completeCount = completeCountRes.data.listFundingRequests.items.length

      const totalPending = pendingCount + partnerCounts.partnerPendingRes
      const totalInProgress = inProgressCount + partnerCounts.partnerInProgressRes
      const totalOutcomeNeeded = outcomeNeededCount + partnerCounts.partnerOutcomeNeededRes
      const totalComplete = completeCount + partnerCounts.partnerCompleteCountRes

      this.setState({
        pendingCount: pendingRes.data.listFundingRequests.nextToken || totalPending > 999 ? '999+' : totalPending,
        inProgressCount: inProgressRes.data.listFundingRequests.nextToken || totalInProgress > 999 ? '999+' : totalInProgress,
        outcomeNeededCount: outcomeNeededRes.data.listFundingRequests.nextToken || totalOutcomeNeeded > 999 ? '999+' : totalOutcomeNeeded,
        completeCount: completeCountRes.data.listFundingRequests.nextToken || totalComplete > 999 ? '999+' : totalComplete
      })
    } catch (e) {
      console.log("Error fetching funding request status: ", e)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isPartnerLeader !== prevProps.isPartnerLeader){
      this.updateCounts()
    }
  }

  render() {
    const {pendingCount, inProgressCount, outcomeNeededCount, completeCount} = this.state

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Funding Request Status Overview</h2>
              <div className="awsui-util-container-header-description">{
                dataScopeDisclaimer(this.props.isPortfolioManager, this.props.isPartnerLeader)
              }</div>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button href="#/FundingRequest/list">View Requests</Button>
            </div>
          </div>

        </div>
        <ColumnLayout columns={2} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            <div>
              <div className="awsui-util-label">Pending Approval</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {pendingCount !== null ? pendingCount : '-'}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">In Progress</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {inProgressCount !== null ? inProgressCount : '-'}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">Outcome Needed</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {outcomeNeededCount !== null ? outcomeNeededCount : '-'}
              </span>
            </div>
            <div>
              <div className="awsui-util-label">Complete</div>
              <span className="awsui-text-large awsui-util-pb-n">
                {completeCount !== null ? completeCount : '-'}
              </span>
            </div>
          </div>
        </ColumnLayout>
      </div>
    )
  }
}
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $id: ID
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPartners(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          createdAt
          updatedAt
        }
        allocatedBudget
        consumedBudget
        fundingRequests(limit: 1000) {
          items {
            id
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      createdAt
      updatedAt
    }
  }
`;

export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      createdAt
      updatedAt
    }
  }
`;
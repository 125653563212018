import {Icon} from "@amzn/awsui-components-react";
import React from "react";
import Tooltip from "@amzn/awsui-components-react/polaris/tooltip";
import Button from "@amzn/awsui-components-react/polaris/button/button";

const statusToWords = {
    Assigned: "Assigned",
    Available: "Available",
    AssignedPendingApproval: "Assigned Pending Approval",
}

export const CreditCodeStatusBadge = ({status}) => {
  let iconName = "status-info"
  let colorClass = "awsui-util-status-inactive"

  if (status === 'Assigned') {
    iconName = "status-info"
    colorClass = "awsui-util-status-info"
  } else if (status === 'Available') {
    iconName = "status-positive"
    colorClass = "awsui-util-status-positive"
  } else if (status === 'AssignedPendingApproval') {
    iconName = "status-pending"
    colorClass = "awsui-util-status-info"
  }

  return (
    <span className={colorClass}>
      <Icon name={iconName}/>
      <span>{` ${statusToWords[status]}`}</span>
    </span>
  )
}

export const COLUMN_DEFINITIONS = (onRevealCode) => [
  {
    id: 'id',
    header: () => 'Code ID (Internal)',
    cell: item => item.id,
    minWidth: '180px',
    allowLineWrap: true
  },
  {
    id: 'code',
    cell: item => <><Button onClick={() => onRevealCode(item)}>View</Button></>,
    header: () => 'Credit Code',
    minWidth: '110px',
    allowLineWrap: true
  },
  {
    id: 'denomination',
    header: () => 'Denomination',
    cell: item => '$' + item.denomination,
    minWidth: '110px',
    allowLineWrap: true
  },
  {
    id: 'status',
    header: () => 'Status',
    cell: item => {
      if (item.assignedToRequestId){
        return <>
          <CreditCodeStatusBadge status={item.status} /><> </>
          <Tooltip text="Open the attached funding request">
            <a href={`#/FundingRequest/view/` + item.assignedToRequestId}> <Icon name="external"/></a>
          </Tooltip>
        </>
      } else {
        return <CreditCodeStatusBadge status={item.status} />
      }
    },
    minWidth: '140px',
    allowLineWrap: true
  }
];

export const SORTABLE_COLUMNS = [
  { id: 'denomination', field: 'denomination'},
  { id: 'status', field: 'status'},
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Funding request properties',
    options: [
      { id: 'id', label: 'Code ID (internal)', editable: true, visible: true },
      { id: 'denomination', label: 'Denomination', editable: false, visible: true},
      { id: 'status', label: 'Status', editable: false, visible: true},
      { id: 'code', label: 'Code', editable: true, visible: true },
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Credit Codes' },
  { value: 30, label: '30 Credit Codes' },
  { value: 50, label: '50 Credit Codes' }
];


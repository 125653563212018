import {AppLayout} from '@amzn/awsui-components-react';
import React from "react";
import Navigation from "../Navigation/Navigation";
import {FundingRequestDashPanel} from "./FundingRequestOverviewPanel";
import {PartnerDashPanel} from "./PartnerOverviewPanel";
import {RecentRequestsPanel} from "./RecentFundingRequestsPanel";
import {CreditCodeOverviewPanel} from "./CreditCodeStatusPanel";
import {PartnerOverviewPanel} from "./PartnerListPanel";
import {API, graphqlOperation} from "aws-amplify";
import {listMyPartners} from "../../graphql/psaLeaderAccess";
import {listPartners} from "../../graphql/portfolioManagerAccess";
import {TotalFundingOverviewPanel} from "./TotalFundingOverviewPanel";


export default class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: {
        loading: true,
        items: []
      }
    }

    this.fetchPartners = this.fetchPartners.bind(this)
  }

  componentDidMount() {
    this.fetchPartners()
  }

  async fetchPartners() {
    try {
      const isPortfolioManager = this.props.authState.groups.indexOf("PortfolioManagers") !== -1
      let partnerRequestsRes = null
      if (isPortfolioManager) {
        this.setState({loading: true})
        partnerRequestsRes = await API.graphql(graphqlOperation(listPartners));
      } else {
        partnerRequestsRes = await API.graphql(graphqlOperation(listMyPartners, {
          alias: this.props.authState.username
        }));
      }

      const sortedPartners = partnerRequestsRes.data.listPartners.items.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else if (b.name > a.name) {
          return -1
        } else {
          return 0
        }
      })

      this.setState({partners: {loading: false, items: sortedPartners}})
    } catch (e) {
      console.log("Error fetching partners: ", e)
      this.setState((prevState) => {
        return {partners: {...prevState.partners, loading: false}}
      })
    }
  }

  render() {
    const isPartnerLeader = this.state.partners.loading ? null : this.state.partners.items.length > 0
    const isPortfolioManager = this.props.authState.groups.indexOf("PortfolioManagers") !== -1

    const partnerPairs = this.state.partners.items.reduce((result, value, index, array) => {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, [])

    return (
      <AppLayout
        navigation={<Navigation authState={this.props.authState}/>}
        navigationOpen={true}
        content={<div>
          <h1 className="awsui-util-mb-m">Dashboard</h1>
          <div className="awsui-grid">
            {isPortfolioManager ?
              <div className="awsui-row">
                <div className="col-12 col-l-4 col-xl-3">
                  <TotalFundingOverviewPanel/>
                </div>
                <div className="col-12 col-l-4 col-xl-4">
                  <FundingRequestDashPanel
                    username={this.props.authState.username}
                    isPartnerLeader={isPartnerLeader}
                    isPortfolioManager={isPortfolioManager}
                  />
                </div>
                <div className="col-12 col-l-4 col-xl-5">
                  <CreditCodeOverviewPanel/>
                </div>
              </div>
              :
              <div className="awsui-row">
                <div className="col-12 col-l-4 col-xl-4">
                  <FundingRequestDashPanel
                    username={this.props.authState.username}
                    isPartnerLeader={isPartnerLeader}
                    isPortfolioManager={isPortfolioManager}
                  />
                </div>
                <div className="col-12 col-l-8 col-xl-8">
                  <RecentRequestsPanel
                    username={this.props.authState.username}
                    isPartnerLeader={isPartnerLeader}
                    isPortfolioManager={isPortfolioManager}
                  />
                </div>
              </div>
            }

            <div className="awsui-row">
              {isPortfolioManager ?
                <>
                <div className="col-12 col-xl-6">
                  <PartnerOverviewPanel/>
                </div>
                <div className="col-12 col-xl-6">
                  <RecentRequestsPanel
                    username={this.props.authState.username}
                    isPartnerLeader={isPartnerLeader}
                    isPortfolioManager={isPortfolioManager}
                  />
                </div>
                </>
                :
                null
              }

            </div>
            { isPartnerLeader && !isPortfolioManager ?
              partnerPairs.map((partnerPair) =>
                <div className="awsui-row" key={partnerPair[0].id}>
                  <div className="col-12 col-l-12 col-xl-6">
                    <PartnerDashPanel partner={partnerPair[0]} isPortfolioManager={isPortfolioManager}/>
                  </div>
                  {partnerPair.length > 1 ?
                    <div className="col-12 col-l-12 col-xl-6">
                      <PartnerDashPanel partner={partnerPair[1]} isPortfolioManager={isPortfolioManager}/>
                    </div>
                    :
                    null
                  }
                </div>
              )
              :
              null
            }
          </div>
        </div>}
        toolsHide={true}
      />
    )
  }
}
import {Flash, Spinner} from "@amzn/awsui-components-react";
import React from "react";

export const RequestLoading = () => {
  return (
    <div>
      <h1><Spinner size={'big'}/> Loading Funding Request</h1>
    </div>
  )
}

export const ErrorAlert = ({header, errorText, onRetry}) => {
  return (errorText ?
    <Flash type="error"
           header={header}
           content={errorText}
           buttonText="Retry"
           onButtonClick={onRetry}
    />
    : null)
}

export function areDenominationsEqual(denoms1, denoms2) {
  if (!denoms1 && !denoms2) {
    // Both null, so equal
    return true
  }
  if ((!denoms1 && denoms2) || (denoms1 && !denoms2)) {
    // One null, one not, unequal
    return false
  }

  // If we've gotten this far, then they're both non null and we need to validate equality on a per-denomination basis
  const dict1 = {}
  const dict2 = {}
  for (const denom of denoms1) {
    dict1[denom.value] = denom.quantity
  }
  for (const denom of denoms2) {
    dict2[denom.value] = denom.quantity
  }

  for (const val in dict1) {
    if (dict1[val] !== dict2[val]) {
      return false
    }
  }

  for (const val in dict2) {
    if (dict1[val] !== dict2[val]) {
      return false
    }
  }

  return true
}

export const dataScopeDisclaimer = (isPortfolioManager, isPartnerLeader) => {
  return (
    isPortfolioManager ?
      "Across all partners"
      :
      isPartnerLeader ?
        "For your partner(s)"
        :
        "For all requests made by you, or where you are listed as the PSA or additional contact"
  )
}
import React from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Table from "@amzn/awsui-components-react/polaris/table";
import {API, graphqlOperation} from "aws-amplify";
import {listCreditCodeDenominations} from "../../graphql/autogen/queries";

const CREDIT_CODE_COLUMN_DEFINITIONS = [
  {
    id: 'value',
    header: () => 'Value',
    cell: item => '$' + item.value,
    minWidth: '100px',
    allowLineWrap: false
  },
  {
    id: 'totalQuantity',
    header: () => 'Total Quantity',
    cell: item => item.totalQuantity,
    minWidth: '100px',
    allowLineWrap: false
  },
  {
    id: 'availableQuantity',
    header: () => 'Available Quantity',
    cell: item => item.availableQuantity,
    minWidth: '100px',
    allowLineWrap: false
  }
];

export class CreditCodeOverviewPanel extends React.Component {
  constructor(props) {
      super(props);

    this.state = {
      denominations: [],
      loading: false
    }

    this.fetchDenominationStatus = this.fetchDenominationStatus.bind(this)
  }

  async fetchDenominationStatus(){
    this.setState({loading: true})
    try {
      const denominationsRes = await API.graphql(graphqlOperation(listCreditCodeDenominations));
      const sortedCodes = denominationsRes.data.listCreditCodeDenominations.items.sort((a, b) => {
        if (a.value > b.value){
          return 1
        } else if (b.value > a.value){
          return -1
        } else {
          return 0
        }
      })
      this.setState({denominations: sortedCodes})
    } catch (e) {
      console.log("Error fetching denominations: ", e)
    } finally {
      this.setState({loading: false})
    }
  }

  componentDidMount() {
    this.fetchDenominationStatus()
  }


  render() {
    return (
      <div className="awsui-util-container awsui-util-no-gutters">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Credit Code Status</h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button href={"#/CreditCode/list"}>View Credit Codes</Button>
            </div>
          </div>
        </div>
        <Table
          variant="borderless"
          columnDefinitions={CREDIT_CODE_COLUMN_DEFINITIONS}
          items={this.state.denominations}
          loading={this.state.loading}
          loadingText={"Loading credit code status..."}
          empty={<div className="awsui-util-p-m">
            <div className="awsui-util-pb-s">No credit codes</div>
            <Button href="#/CreditCode/import">Import Codes</Button>
          </div>}
        >
        </Table>
      </div>
    )
  }
}
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      fundingRequests {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listMyPartners = /* GraphQL */ `
query ListMyPartners(
  $alias: String!
) {
  listPartners(
    filter: {or: [{partnerLeaderAlias: {eq: $alias}}{partnerSecondaryAlias: {eq: $alias}}]}
  ) {
    items {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        createdAt
        updatedAt
      }
      allocatedBudget
      consumedBudget
      fundingRequests(limit: 1000) {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`

export const countFundingRequestsForMyPartnersByStatus = /* GraphQL */ `
query CountFundingRequestsForMyPartners(
  $leader: String!
  $status: FundingStatus!
) {
  listPartners(
    filter: {or: [{partnerLeaderAlias: {eq: $leader}}, {partnerSecondaryAlias: {eq: $leader}}]}
  ) {
    items {
      fundingRequests(filter: {status: {eq: $status}}, limit: 1000) {
        items {
          id
        },
        nextToken
      }
    }
  }
}
`

export const countInProgressFundingRequestsForMyPartners = /* GraphQL */ `
query CountFundingRequestsForMyPartners(
  $leader: String!
) {
  listPartners(
    filter: {or: [{partnerLeaderAlias: {eq: $leader}}, {partnerSecondaryAlias: {eq: $leader}}]}
  ) {
    items {
      fundingRequests(filter: {or: [{status: {eq: ProjectInProgress}}, {status: {eq: ApprovedPendingCodes}}]}, limit: 1000) {
        items {
          id
        },
        nextToken
      }
    }
  }
}
`

export const getFundingRequestsForMyPartners = /* GraphQL */ `
query GetPartner(
  $leaderAlias: String!
  $filter: ModelFundingRequestFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPartners(
    filter: {or: [{partnerLeaderAlias: {eq: $leaderAlias}}, {partnerSecondaryAlias: {eq: $leaderAlias}}]}
  ) {
    items {
      id
      fundingRequests(filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
        items {
        id
        stepFunctionExecutionARN
        stepFunctionCallbackToken
        status
        projectName
        partnerId
        partner {
          id
          name
          partnerLeaderAlias
          partnerSecondaryAlias
          createdAt
          updatedAt
        }
        geoId
        geo {
          id
          name
          createdAt
          updatedAt
        }
        projectType
        projectDate
        requestorAlias
        requestor {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        projectPSAAlias
        projectPSA {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        additionalContacts
        totalRequested
        requestedDenominations {
          value
          quantity
        }
        description
        reportedOutcomes
        submittedAt
        lastUpdated
      }
      nextToken
      }
    }
  }
}
`;

export const listFundingRequestsForPartner = /* GraphQL */ `
query ListFundingRequestsForPartner(
  $partnerId: ID!
  $filter: ModelFundingRequestFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
    getPartner(id: $partnerId) {
      fundingRequests(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          id
          stepFunctionExecutionARN
          stepFunctionCallbackToken
          status
          projectName
          partnerId
          partner {
            id
            name
            partnerLeaderAlias
            partnerSecondaryAlias
            createdAt
            updatedAt
          }
          geoId
          geo {
            id
            name
            createdAt
            updatedAt
          }
          projectType
          projectDate
          requestorAlias
          requestor {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          projectPSAAlias
          projectPSA {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          additionalContacts
          totalRequested
          requestedDenominations {
            value
            quantity
          }
          description
          reportedOutcomes
          submittedAt
          lastUpdated
        }
        nextToken
      }
    }
  }
`;

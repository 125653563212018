import React from "react";
import {Button, ColumnLayout, ExpandableSection, Flash, FormField, Modal,} from "@amzn/awsui-components-react";
import {API, graphqlOperation} from "aws-amplify";
import {updateFundingRequest} from "../../graphql/standardAccess";
import {
  build_expected_outcomes,
  enable_expected_outcomes,
  evangelize_expected_outcomes
} from "../../resources/BEEFundingDescriptions";
import {RequiredTextArea} from "../FormComponents/RequiredInputs";

export default class OutcomesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAttemptedSubmit: false,
      submitErrorText: "",
      outcomesText: this.props.preFilledText || "",
      tryingToSubmit: false
    }


    this.handleCancel = this.handleCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.preFilledText !== this.props.preFilledText) {
      this.setState({outcomesText: this.props.preFilledText})
    }
  }

  handleCancel(){
    this.setState({
      hasAttemptedSubmit: false,
      submitErrorText: "",
      outcomesText: this.props.preFilledText || "",
      tryingToSubmit: false
    })

    if (this.props.onCancel){
      this.props.onCancel()
    }
  }

  async handleSubmit(){
    this.setState({hasAttemptedSubmit: true})
    const {outcomesText} = this.state
    if (!outcomesText){
      return
    }

    this.setState({submitErrorText: "", tryingToSubmit: true})
    try {
      await API.graphql(graphqlOperation(updateFundingRequest, {
        'input': {
          'id': this.props.fundingRequest.id,
          'reportedOutcomes': outcomesText
        }
      }))
      this.setState({tryingToSubmit: false})
    } catch (e) {
      console.log("Error submitting request: ", e)
      this.setState({tryingToSubmit: false, submitErrorText: e.errors ? e.errors[0].message : e.toString()})
      return
    }

    if (this.props.onSubmit){
      this.props.onSubmit()
    }
  }

  render() {
    const expected_outcomes = {
      'Build': build_expected_outcomes,
      'Evangelize': evangelize_expected_outcomes,
      'Enable': enable_expected_outcomes
    }[this.props.fundingRequest.projectType]

    return (
      <Modal
        visible={this.props.visible}
        header={`Report Project Outcomes`}
        onDismiss={this.handleCancel}
        footer={<span className="awsui-util-f-r">
                  <Button variant="link" onClick={this.handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary"
                          onClick={this.handleSubmit}
                          loading={this.state.tryingToSubmit}
                  >
                    Update Project Outcomes
                  </Button>
                </span>}
      >
        <ColumnLayout borders="horizontal">
          <div data-awsui-column-layout-root="true">
            {this.state.submitErrorText ?
              <Flash type="error" header={`Error Updating Project Outcomes`}
                     buttonText={"Retry"} onButtonClick={this.handleSubmit}>{this.state.submitErrorText}
              </Flash>
              :
              null
            }
            <div>
              {!this.props.fundingRequest.requestedOutcomes ?
                <p>
                  In order to mark this request as completed, you will need to report project outcomes based on the
                  project type <i>{this.props.fundingRequest.projectType}</i>.
                </p>
                :
                <p>
                  Project outcome expectations based on project type <i>{this.props.fundingRequest.projectType}</i>.
                </p>
              }
              <ExpandableSection header={`View Expected Outcomes For ${this.props.fundingRequest.projectType} Projects`}>
                {expected_outcomes}
              </ExpandableSection>
            </div>
            <FormField label={<span>Project outcomes</span>}
                       description="Describe the outcomes of this project according to the above criteria">
              <RequiredTextArea value={this.state.outcomesText}
                                hasSubmitted={this.state.hasAttemptedSubmit}
                                onChange={(e) => this.setState({outcomesText: e.detail.value})}/>
            </FormField>
          </div>
        </ColumnLayout>
      </Modal>
    )
  }
}
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $id: ID
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPartners(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const submitFundingRequest = /* GraphQL */ `
mutation SubmitFundingRequestStandardAccess($input: CreateFundingRequestInput!) {
  submitFundingRequest(input: $input) {
    id
    stepFunctionExecutionARN
    stepFunctionCallbackToken
    status
    projectName
    partnerId
    partner {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    geoId
    geo {
      id
      name
      createdAt
      updatedAt
    }
    projectType
    projectDate
    requestorAlias
    requestor {
      alias
      fullName
      createdAt
      updatedAt
    }
    projectPSAAlias
    projectPSA {
      alias
      fullName
      createdAt
      updatedAt
    }
    additionalContacts
    totalRequested
    requestedDenominations {
      value
      quantity
    }
    description
    discussionThread {
      items {
        id
        fromAlias
        type
        createdAt
        fundingRequestId
        actionType
        modifiedProp
        newVal
        content
      }
      nextToken
    }
    reportedOutcomes
    submittedAt
    lastUpdated
  }
}
`;

export const listCreditCodeDenominations = /* GraphQL */ `
  query ListCreditCodeDenominations(
    $value: Int
    $filter: ModelCreditCodeDenominationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreditCodeDenominations(
      value: $value
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const listFundingRequestsSortBySubmitted = /* GraphQL */`
query ListFundingRequestsSortBySubmitted(
  $filter: ModelFundingRequestFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listFundingRequestsSortBySubmitted(
    alwaysSeven: 7
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerSecondaryAlias
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      description
      reportedOutcomes
      submittedAt
      lastUpdated
    }
    nextToken
  }
}
`

export const getFundingRequest = /* GraphQL */`
query GetFundingRequest($id: ID!) {
  getFundingRequest(id: $id) {
    id
    stepFunctionExecutionARN
    stepFunctionCallbackToken
    status
    projectName
    partnerId
    partner {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    geoId
    geo {
      id
      name
      createdAt
      updatedAt
    }
    projectType
    projectDate
    requestorAlias
    requestor {
      alias
      fullName
      createdAt
      updatedAt
    }
    projectPSAAlias
    projectPSA {
      alias
      fullName
      createdAt
      updatedAt
    }
    additionalContacts
    totalRequested
    requestedDenominations {
      value
      quantity
    }
    description
    discussionThread {
      items {
        id
        fromAlias
        from {
          fullName
          alias
          email
        }
        type
        createdAt
        fundingRequestId
        actionType
        modifiedProp
        newVal
        content
      }
      nextToken
    }
    reportedOutcomes
    submittedAt
    lastUpdated
  }
}
`

export const getFundingRequestAuthByPartner = /* GraphQL */`
query GetFundingRequestAuthByPartner($id: ID!) {
  getFundingRequestAuthByPartner(id: $id) {
    id
    stepFunctionExecutionARN
    stepFunctionCallbackToken
    status
    projectName
    partnerId
    partner {
      id
      name
      partnerLeaderAlias
      partnerLeader {
        alias
        fullName
        createdAt
        updatedAt
      }
      partnerSecondaryAlias
      partnerSecondary {
        alias
        fullName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    geoId
    geo {
      id
      name
      createdAt
      updatedAt
    }
    projectType
    projectDate
    requestorAlias
    requestor {
      alias
      fullName
      createdAt
      updatedAt
    }
    projectPSAAlias
    projectPSA {
      alias
      fullName
      createdAt
      updatedAt
    }
    additionalContacts
    totalRequested
    requestedDenominations {
      value
      quantity
    }
    description
    discussionThread {
      items {
        id
        fromAlias
        from {
          fullName
          alias
          email
        }
        type
        createdAt
        fundingRequestId
        actionType
        modifiedProp
        newVal
        content
      }
      nextToken
    }
    reportedOutcomes
    submittedAt
    lastUpdated
  }
}
`

export const listThreadItemsByFundingRequest = /* GraphQL */ `
  query ListThreadItemsByFundingRequest(
    $fundingRequestId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelThreadItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadItemsByFundingRequest(
      fundingRequestId: $fundingRequestId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromAlias
        from {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        type
        createdAt
        fundingRequestId
        actionType
        modifiedProp
        newVal
        content
      }
      nextToken
    }
  }
`;

export const createThreadItem = /* GraphQL */ `
  mutation CreateThreadItem(
    $input: CreateThreadItemInput!
    $condition: ModelThreadItemConditionInput
  ) {
    createThreadItem(input: $input, condition: $condition) {
      id
      fromAlias
      from {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      type
      createdAt
      fundingRequestId
      actionType
      modifiedProp
      newVal
      content
    }
  }
`;

export const applyActionAgainstFundingRequest = /* GraphQL */ `
  mutation ApplyActionAgainstFundingRequest(
    $fundingRequestId: ID!
    $action: ActionType!
    $accompanyingComment: String
    $denominationsOverride: [DenominationInput]
  ) {
    applyActionAgainstFundingRequest(
      fundingRequestId: $fundingRequestId
      action: $action
      accompanyingComment: $accompanyingComment
      denominationsOverride: $denominationsOverride
    ) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      description
      discussionThread {
        items {
          id
          fromAlias
          from {
            alias
            fullName
            email
            createdAt
            updatedAt
          }
          type
          createdAt
          fundingRequestId
          actionType
          modifiedProp
          newVal
          content
        }
        nextToken
      }
      reportedOutcomes
      submittedAt
      lastUpdated
    }
  }
`;

export const updateFundingRequest = /* GraphQL */ `
  mutation UpdateFundingRequest($input: UpdateFundingRequestInput!) {
    updateFundingRequest(input: $input) {
      id
      stepFunctionExecutionARN
      stepFunctionCallbackToken
      status
      projectName
      partnerId
      partner {
        id
        name
        partnerLeaderAlias
        partnerLeader {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        partnerSecondaryAlias
        partnerSecondary {
          alias
          fullName
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      geoId
      geo {
        id
        name
        createdAt
        updatedAt
      }
      projectType
      projectDate
      requestorAlias
      requestor {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      projectPSAAlias
      projectPSA {
        alias
        fullName
        email
        createdAt
        updatedAt
      }
      additionalContacts
      totalRequested
      requestedDenominations {
        value
        quantity
      }
      description
      reportedOutcomes
      submittedAt
      lastUpdated
    }
  }
`;

export const listCreditCodesForFundingRequest = /* GraphQL */ `
query ListCreditCodesForFundingRequest(
  $fundingRequestId: ID!
  $filter: ModelCreditCodeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
    getFundingRequestAuthByPartner(id: $fundingRequestId) {
      assignedCreditCodes(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          id
          code
          denomination
          status
          assignedToRequestId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
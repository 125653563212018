import {Icon} from "@amzn/awsui-components-react";
import React from "react";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";


export const COLUMN_DEFINITIONS = (showCreditCodes, decryptingCodes)  => [
  {
    id: 'id',
    header: () => 'Code ID (Internal)',
    cell: item => item.id,
    minWidth: '180px',
    allowLineWrap: true
  },
  {
    id: 'denomination',
    header: () => 'Denomination',
    cell: item => '$' + item.denomination,
    minWidth: '50px',
    allowLineWrap: true
  },
  {
    id: 'code',
    cell: item => {
      if (showCreditCodes) {
        if (item.decrypted_code) {
          return item.decrypted_code
        } else {
          if (item.decrypted_code === null){
            return <span className="awsui-util-status-negative"><Icon name="status-negative"/> Decryption Error</span>
          } else {
            return <><Spinner/> Decrypting...</>
          }
        }
      } else {
        return "XXXXXXXXXXXXXXXXXXXXXXXX"
      }
    },
    header: () => 'Credit Code',
    minWidth: '110px',
    allowLineWrap: true
  }
];

export const SORTABLE_COLUMNS = [
  { id: 'denomination', field: 'denomination'}
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Credit code properties',
    options: [
      { id: 'denomination', label: 'Denomination', editable: false, visible: true},
      { id: 'code', label: 'Credit Code', editable: true, visible: true },
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Credit Codes' },
  { value: 30, label: '30 Credit Codes' },
  { value: 50, label: '50 Credit Codes' }
];


import '../styles/App.css';
import React, {Component} from "react";
import {Route} from "react-router-dom"
import SubmitFundingRequestPage from "./FundingRequestForm/SubmitFundingRequestPage";
import HomeComponent from "./Home";
import FundingRequestListPage from "./FundingRequestList/FundingRequestListPage";
import ViewRequestPage from "./ViewRequest/ViewRequestPage";
import EditFundingRequestPage from "./FundingRequestForm/EditFundingRequestPage";
import Amplify, {Auth} from 'aws-amplify';
import {AmplifyGreetings, withAuthenticator} from "@aws-amplify/ui-react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import awsconfig from "../aws-exports"
import DashboardPage from "./Dashboard/DashboardPage";
import EditPartnerPage from "./PartnerForm/EditPartnerPage";
import AddPartnerPage from "./PartnerForm/AddPartnerPage";
import CreditCodeListPage from "./CreditCodeList/CreditCodeListPage";
import ImportCreditCodesPage from "./CreditCodes/ImportCreditCodesPage";
import GlobalSettingsPage from "./GlobalSettings/GlobalSettingsPage";

Amplify.configure(awsconfig);

class App extends Component {
  render() {
    const authState = {
      username: Auth.user.username,
      groups: Auth.user.signInUserSession.idToken.payload['cognito:groups'] || []
    }

    //console.log(Auth.user.signInUserSession.idToken.jwtToken)

    if (authState.groups.indexOf("FundingRequestors") === -1) {
      return (
        <div id="b">
          <div id="h">
            <AmplifyGreetings
              username={authState.username}
              inGreeting={(username) => 'Signed in as: ' + username}
              outGreeting="Please sign in..."
            />
          </div>
          <div id="c" className="awsui">
            <div className={"unauth-container"}>
              <Alert header="Unauthorized" type="error">
                User must be in "FundingRequestors" cognito group to have access to this tool
              </Alert>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div id="b">
        <div id="h">
          <AmplifyGreetings
            username={authState.username}
            inGreeting={(username) => 'Signed in as: ' + username}
            outGreeting="Please sign in..."
          />
        </div>
        <div id="c" className="awsui">
          <Route exact path="/" render={(props) => <HomeComponent authState={authState} {...props} {...props}/>}/>
          <Route path="/Dashboard" render={(props) => <DashboardPage authState={authState} {...props} {...props}/>}/>
          <Route path="/FundingRequest/list"
                 render={(props) => <FundingRequestListPage authState={authState} {...props}/>}/>
          <Route path="/FundingRequest/submit"
                 render={(props) => <SubmitFundingRequestPage authState={authState} {...props}/>}/>
          <Route path="/FundingRequest/view/:id"
                 render={(props) => <ViewRequestPage authState={authState} {...props}/>}/>
          <Route path="/FundingRequest/edit/:id"
                 render={(props) => <EditFundingRequestPage authState={authState} {...props}/>}/>
          <Route path="/Partner/edit/:id"
                 render={(props) => <EditPartnerPage authState={authState} {...props}/>}/>
          <Route path="/Partner/add"
                 render={(props) => <AddPartnerPage authState={authState} {...props}/>}/>
          <Route path="/CreditCode/list"
                 render={(props) => <CreditCodeListPage authState={authState} {...props}/>}/>
          <Route path="/CreditCode/import"
                 render={(props) => <ImportCreditCodesPage authState={authState} {...props}/>}/>
          <Route path="/EditGlobalSettings"
                 render={(props) => <GlobalSettingsPage authState={authState} {...props}/>}/>
        </div>
      </div>
    );
  }

}

export default withAuthenticator(App);

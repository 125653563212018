import ValidatedInput from "./ValidatedInput";
import React from "react";
import {Textarea} from "@amzn/awsui-components-react";
import Icon from "@amzn/awsui-components-react/polaris/icon";


class RequiredInput extends ValidatedInput {
  async is_valid(fieldText) {
    if (fieldText === "") {
      return [false, "Required field"]
    }

    return [true, ""]
  }
}


class RequiredTextArea extends React.Component {
  constructor(props) {
    super(props);

    let value = "";
    if (props.hasOwnProperty("value")) {
      value = props.value;
    }

    this.state = {value: value}
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value){
      this.setState({value: this.props.value})
    }
  }

  render() {
    const invalid = this.props.hasSubmitted === true && this.state.value === "";
    return (
      <div>
        <Textarea {...this.props}
                  value={this.state.value}
                  invalid={invalid}
                  onInput={e => {
                    this.setState({value: e.detail.value})
                    if (this.props.hasOwnProperty("onInput")){
                      this.props.onInput(e)
                    }
                  }}/>
         {invalid ?
          <span className="error-text awsui-util-pt-xs display-block awsui-form-field-description">
            <Icon name="status-warning"/> Required field</span> : null}
      </div>
    )
  }
}

export {RequiredInput, RequiredTextArea}
import React from 'react';
import {withRouter} from "react-router-dom"
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";

class Navigation extends React.Component {
  // If the provided link is empty, do not redirect pages
  onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      this.props.history.push(ev.detail.href.substring(1));
    }
  }

  render() {
    return (
      <SideNavigation
        header={{ text: 'BEE Funding Tool', href: '#/' }}
        items={items(this.props.authState)}
        activeHref={`#${this.props.location.pathname}`}
        onFollow={this.onFollowHandler.bind(this)}
      />
    );
  }
}

const items = (authState) => {
  const baseItems = [
    { type: 'link', text: 'Dashboard', href: '#/Dashboard' },
    { type: 'link', text: 'Funding Requests', href: '#/FundingRequest/list' },
  ]

  if (authState.groups.indexOf("PortfolioManagers") !== -1) {
    baseItems.push({ type: 'link', text: 'Credit Codes', href: '#/CreditCode/list' },)
    baseItems.push({ type: 'link', text: 'Global Configuration', href: '#/EditGlobalSettings' },)
  }

  return baseItems
};

export default withRouter(Navigation);

import React from "react";
import {FundingRequestStatusBadge, statusToWords} from "../FormComponents/FundingRequestStatusBadge";

export const COLUMN_DEFINITIONS = [
  {
    id: 'projectName',
    header: () => 'Project Name',
    cell: item => (
      <div>
        <a href={`#/FundingRequest/view/${item.id}`}>{item.projectName}</a>
      </div>
    ),
    minWidth: '180px',
    allowLineWrap: true
  },
  {
    id: 'projectType',
    cell: item => item.projectType,
    header: () => 'Project Type',
    minWidth: '110px',
    allowLineWrap: true
  },
  {
    id: 'partner',
    header: () => 'Partner',
    cell: item => item.partner.name,
    minWidth: '120px',
    allowLineWrap: true
  },
  {
    id: 'geo',
    header: () => 'Region',
    cell: item => item.geo.name,
    minWidth: '110px',
    allowLineWrap: true
  },
  {
    id: 'requestor',
    header: () => 'Requested By',
    cell: item => item.requestor.fullName,
    minWidth: '140px',
    allowLineWrap: true
  },
  {
    id: 'projectPSA',
    header: () => 'Project PSA',
    cell: item => item.projectPSA.fullName,
    minWidth: '120px',
    allowLineWrap: true
  },
  {
    id: 'additionalContacts',
    header: () => 'Additional Contacts',
    cell: item => {
      if (item.additionalContacts) {
        return <ul className="plain-compact">{item.additionalContacts.map((alias, i) => <li key={i}>{alias}@</li>)}</ul>
      } else {
        return ''
      }
    },
    minWidth: '130px',
    allowLineWrap: true
  },
  {
    id: 'projectDate',
    header: () => 'Project Date',
    cell: item => item.projectDate ? item.projectDate.slice(0,10) : '',
    minWidth: '120px',
    allowLineWrap: true
  },
  {
    id: 'status',
    header: () => 'Status',
    cell: item => <FundingRequestStatusBadge status={item.status}/>,
    minWidth: '200px',
    allowLineWrap: true
  },
  {
    id: 'description',
    header: () => 'Description',
    cell: item => item.description,
    minWidth: '160px',
    allowLineWrap: false
  },
  {
    id: 'totalRequested',
    header: () => 'Total Requested',
    cell: item => '$' + item.totalRequested,
    minWidth: '130px',
    allowLineWrap: true
  },
  {
    id: 'submittedAt',
    header: () => 'Submitted At',
    cell: item => new Date(item.submittedAt).toString().split(' (')[0],
    minWidth: '280px',
    allowLineWrap: true
  }
];

export const SORTABLE_COLUMNS = [
  { id: 'projectName', comparator: (a, b) => {return a.projectName.localeCompare(b.projectName)}},
  { id: 'projectType', field: 'projectType'},
  { id: 'partner', comparator: (a, b) => a.partner.name.localeCompare(b.partner.name)},
  { id: 'geo', comparator: (a, b) => a.geo.name.localeCompare(b.geo.name)},
  { id: 'requestor', comparator: (a, b) => a.requestor.fullName.localeCompare(b.requestor.fullName)},
  { id: 'projectPSA', comparator: (a, b) => a.projectPSA.fullName.localeCompare(b.projectPSA.fullName)},
  {
    id: 'projectDate',
    field: 'projectDate',
    comparator: (a, b) => {
      if (a.projectDate === null || b.projectDate === null){
        if (a.projectDate === b.projectDate) {
          return 0
        } else if (a.projectDate !== null){
          return -1
        } else {
          return 1
        }
      }

      return a.projectDate.localeCompare(b.projectDate)
    }
  },
  { 
    id: 'status',
    comparator: (a, b) => {
      if (statusToWords[a.status] < statusToWords[b.status]) {
        return -1
      } else if (statusToWords[a.status] > statusToWords[b.status]) {
        return 1
      } else {
        return 0
      }
    }
  },
  { id: 'totalRequested', field: 'totalRequested'},
  { id: 'submittedAt', field: 'submittedAt'}
];

export const CONTENT_SELECTOR_OPTIONS = (isApprover) => [
  {
    label: 'Funding request properties',
    options: [
      { id: 'projectName', label: 'Project Name', editable: false, visible: true },
      { id: 'projectType', label: 'Project Type', editable: true, visible: false },
      { id: 'partner', label: 'Partner', editable: true, visible: true},
      { id: 'geo', label: 'Region', editable: true, visible: false},
      { id: 'requestor', label: 'Requested By', editable: true, visible: isApprover},
      { id: 'projectPSA', label: 'Project PSA', editable: true, visible: !isApprover},
      { id: 'additionalContacts', label: 'Additional Contacts', editable: true, visible: false },
      { id: 'projectDate', label: 'Project Date', editable: true, visible: false },
      { id: 'status', label: 'Status', editable: true, visible: true },
      { id: 'description', label: 'Description', editable: true, visible: false },
      { id: 'totalRequested', label: 'Total Requested', editable: true, visible: true },
      { id: 'submittedAt', label: 'Submitted At', editable: true, visible: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Funding Requests' },
  { value: 30, label: '30 Funding Requests' },
  { value: 50, label: '50 Funding Requests' }
];

